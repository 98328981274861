import { createAsyncThunk } from "@reduxjs/toolkit";

import LocalStorage from "../../../../Services/Storage/LocalStorage";
import StorageModel from "../../../../Models/StorageModel";
import HttpsClient from "../../../../Services/HttpsClient";
import ApiHelper from "../../../../Services/ApiHelper";
import Cache from "../../../../Services/Cache";
import InventoryAPI from "../API/InventoryAPI";
import { InventoryApiUrls } from "../API/InventoryAPI";
import JSONDataManager from "../../../../Services/JSONDataManager/JSONDataManager";

// import { getTests as entityListGetTests } from "../../EntityList/Store/Thunk";

const storage = new LocalStorage();
const storageModel = new StorageModel(storage);
const cache = new Cache(storageModel);

const httpsClient = new HttpsClient();
const apiHelper = new ApiHelper(httpsClient);
const inventoryAPI = new InventoryAPI(apiHelper, cache);
const jsonDataManager = new JSONDataManager();

export const GetInventories = createAsyncThunk(
  InventoryApiUrls.getInventories,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;

    return await inventoryAPI.getInventories(payload, token);
  }
);

export const CreateInventory = createAsyncThunk(
  InventoryApiUrls.createInventory,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;

    await inventoryAPI.createInventory(payload, token);

    dispatch(GetInventories());

    return;
  }
);

export const UpdateInventory = createAsyncThunk(
  InventoryApiUrls.updateInventory,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;

    await inventoryAPI.updateInventory(payload, token);

    dispatch(GetInventories());

    return;
  }
);

export const DeleteInventory = createAsyncThunk(
  InventoryApiUrls.deleteInventory,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;

    await inventoryAPI.deleteInventory(payload, token);
    dispatch(GetInventories());
  }
);

export const UpsertLeanParameters = createAsyncThunk(
  InventoryApiUrls.upsertLeanParameters,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;

    await inventoryAPI.upsertLeanParameters(payload, token);
    dispatch(GetInventories());
  }
);

export const uploadApplicationBackUp = createAsyncThunk(
  InventoryApiUrls.uploadApplicationBackUp,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;

    await inventoryAPI.uploadInventoryBackUp(payload, token);
    dispatch(GetInventories());

    return;
  }
);

export const getApplicationBackup = createAsyncThunk(
  InventoryApiUrls.getApplicationBackup,
  async (payload, getState) => {
    const state = getState.getState();
    const token = state.auth.user.token;

    const data = await inventoryAPI.getInventoryBackup({ id: payload.id }, token);
    jsonDataManager.downloadJSON(data, payload.name);

    return data;
  }
);
