import { createSlice } from "@reduxjs/toolkit";
import { createReportScheme, deleteReportScheme, getAllReportItems, updateReportScheme } from "./Thunk";
import { GenerateReportItemsArray } from "../Utils/Utils";

const entityTypeToComponentName = {
  All: "All",

  Summary: "BasicGraphSummaryEntity",
  Detail: "BasicGraphDetailEntity",
  Mono: "MonoTextuaryEntity",
  Dual: "DualTextuaryEntity",
};

export const reportScheme = createSlice({
  name: "reportScheme",
  initialState: {
    inventory: null,
    visibleReportItemEntityList: [],
    nonVisibleReportItemEntityList: [],
    entityModalInfo: {
      isShown: false,
      entity: {},
    },
    role: "Admin",
  },
  reducers: {
    updateReportSchemeState(state, action) {
      for (const [key, value] of Object.entries(action.payload)) {
        state[key] = value;
      }
    },
    updateReportSchemeItemHeight(state, action) {
      let { height, order } = action.payload;
      for (let i = 0; i < state.visibleReportItemEntityList.length; i++) {
        let item = state.visibleReportItemEntityList[i];
        if (order === item.order) {
          item.height = height;
          break;
        }
      }
    },

    switchFromVisibleToNonVisible(state, action) {
      let { entity } = action.payload;

      state.visibleReportItemEntityList = state.visibleReportItemEntityList.filter((x) => {
        if (x.id === entity.id && x.componentName === entity.componentName) {
          x.isVisible = false;
          state.nonVisibleReportItemEntityList.unshift(x);
          return false;
        }
        return true;
      });
      state.visibleReportItemEntityList.forEach((item, i) => {
        item.order = i + 1;
      });
      /*

      state.nonVisibleReportItemEntityList.forEach((item, i) => {
        item.order = i + 1;
      });

      */
    },

    switchFromNonVisibleToVisible(state, action) {
      let { entity } = action.payload;

      state.nonVisibleReportItemEntityList = state.nonVisibleReportItemEntityList.filter((x) => {
        if (x.id === entity.id && x.componentName === entity.componentName) {
          x.isVisible = true;
          state.visibleReportItemEntityList.push(x);
          return false;
        }
        return true;
      });

      state.visibleReportItemEntityList.forEach((item, i) => {
        item.order = i + 1;
      });
      /*

      state.nonVisibleReportItemEntityList.forEach((item, i) => {
        item.order = i + 1;
      });

      */
    },

    setEntityModalInfo(state, action) {
      let { isShown, entity } = action.payload;

      state.entityModalInfo.entity = entity;

      state.entityModalInfo.isShown = isShown;
    },

    setRole(state, action) {
      let { role } = action.payload;

      state.role = role;
    },

    switchVisibilityByGroup(state, action) {
      let { componentNameOfGroupToBeShown } = action.payload;
      if (componentNameOfGroupToBeShown === "None") {
        state.visibleReportItemEntityList.forEach((entity) => {
          entity.isVisible = false;
          state.nonVisibleReportItemEntityList.unshift(entity);
        });
        state.visibleReportItemEntityList = [];
      } else {
        state.nonVisibleReportItemEntityList = state.nonVisibleReportItemEntityList.filter((item, i) => {
          switch (componentNameOfGroupToBeShown) {
            case "All":
              item.isVisible = true;
              state.visibleReportItemEntityList.push(item);
              return false;

            // case "None" is not inside the switch, because we need to loop inside visibleReportItemEntityList in order to push them into nonVisible. Filter loop isn't ideal here.
            default:
              if (
                item.componentName === componentNameOfGroupToBeShown ||
                (item.htmlEl === true &&
                  (entityTypeToComponentName[item.reportType] === componentNameOfGroupToBeShown ||
                    entityTypeToComponentName[item.reportType] === "All"))
              ) {
                item.isVisible = true;
                state.visibleReportItemEntityList.push(item);
                return false;
              }
              return true;
          }
        });
      }

      state.visibleReportItemEntityList.forEach((item, i) => {
        item.order = i + 1;
      });
      //^^ do we want to do this ?
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllReportItems.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getAllReportItems.fulfilled, (state, action) => {
      state.isLoading = false;
      state.inventory = action.payload;

      let arrays = GenerateReportItemsArray(action.payload, state.role);
      state.visibleReportItemEntityList = arrays.visibles;
      state.nonVisibleReportItemEntityList = arrays.nonVisibles;
    });

    builder.addCase(createReportScheme.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createReportScheme.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updateReportScheme.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateReportScheme.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(deleteReportScheme.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteReportScheme.fulfilled, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const {
  updateReportSchemeState,
  updateReportSchemeItemHeight,
  switchFromVisibleToNonVisible,
  switchFromNonVisibleToVisible,
  setEntityModalInfo,
  setRole,
  switchVisibilityByGroup,
} = reportScheme.actions;

export default reportScheme.reducer;
