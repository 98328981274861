import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { UpdateCompanyState } from "../Store";
import { deleteCompany } from "../Store/Thunk";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

/*


candidates:null
companyInfo:null
companyUsers:null
credits:null
id:5
name:"ilk Com"
position:null

*/

export default function CompanyList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const companyList = useSelector((state) => state.company.companyList);
  const HandleDeleteButton = (id) => {
    dispatch(deleteCompany({ id }));
  };

  const HandleUpdateButton = (company) => {
    var obj = {
      id: company.id,
      name: company.name,
      isUpdateOperation: true,
    };

    dispatch(UpdateCompanyState(obj));
  };

  const { t } = useTranslation();

  return (
    companyList &&
    companyList.map((company, i) => (
      <Card className="mb-3" key={"assess" + i}>
        <Card.Body>
          <Row xs="auto">
            <Col xs="12" md="10">
              <Row>
                <Col xs={2}>{company.id}</Col>
                <Col xs={8} md="3">
                  {company.name}
                </Col>
                <Col xs={2}>
                  <Button
                    onClick={() => {
                      navigate("/companyUsers", { state: company });
                    }}
                  >
                    {t("usersButtonLabel")}
                  </Button>
                </Col>
              </Row>
              <Row>
                {/*what does the companyUser even have? what do i map here*/}
                <Col className={"bg-danger"}>{company?.companyInfo}</Col>
              </Row>
            </Col>
            <Col md="2">
              <Row xs="auto" className="justify-content-md-end">
                <Col>
                  <Button size="sm" variant="success" onClick={() => HandleUpdateButton(company)}>
                    {t("updateButtonLabel")}
                  </Button>
                </Col>
                <Col>
                  <Button
                    style={{ float: "right" }}
                    variant="danger"
                    size="sm"
                    onClick={() => HandleDeleteButton(company.id)}
                  >
                    {t("deleteButtonLabel")}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    ))
  );
}
