import React from "react";
import ParameterProgressBar from "./ParameterProgressBar";

export default function ConsistencyAnalysis({ score }) {
  return (
    <div className="mb-6">
      <h1> Tutarlılık Analizi</h1>
      <ParameterProgressBar name="Tutarlılık Skoru" score={score} />
    </div>
  );
}
