import { BaseApiUrl } from "../Helpers/ApiHelper";

export default class ApiHelper {
  constructor(httpClient, baseURL, token) {
    this.httpClient = httpClient;
    this.baseURL = BaseApiUrl;
  }

  async post(url, data, token, eAlert, sAlert) {
    const headers = this.getHeaders(token);

    return await this.httpClient.makeRequest("post", url, null, data, headers, eAlert, sAlert);
  }

  async get(url, param, token, eAlert, sAlert) {
    const headers = this.getHeaders(token);

    const response = await this.httpClient.makeRequest("get", url, param, null, headers, eAlert, sAlert);

    return response.data;
  }

  xmlPostFormData(url, formData, token, callback) {
    const request = new XMLHttpRequest();

    request.open("POST", BaseApiUrl + url);
    request.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        if (callback) callback(this);
      }
    };
    request.setRequestHeader("Authorization", `Bearer ${token}`);
    request.send(formData);
  }

  getHeaders(token) {
    return {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Access-Control-Allow-Headers": "Content-Type",
    };
  }
}
