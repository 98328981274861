import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Dropdown from "react-bootstrap/Dropdown";
import Badge from "react-bootstrap/Badge";

import { clearSubScopes, openSubScopeModal } from "../../Store";
import { deleteSubScope, getSubScope } from "../../Store/Thunk";
import IconSvg from "../../../../../App/AppMenu/IconSvg";
import ScrollableCard from "../../../../../SharedComponents/ScrollableCard";

export default function SubScopeList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const subScopes = useSelector((state) => state.scope.subScopes);

  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getSubScope(state.scope.id));

    return () => {
      dispatch(clearSubScopes());
    };
  }, []);

  const openModal = (subScope) => {
    dispatch(openSubScopeModal(subScope));
  };

  const remove = (id) => {
    dispatch(deleteSubScope({ scopeId: state.scope.id, id }));
  };

  const openPage = (url, scope, subScope, application) => {
    navigate(url, {
      state: {
        scope,
        subScope,
        application,
        isSub: true,
      },
    });
  };

  const questionSize = (subScopes, type) => {
    return subScopes.questions.filter((item) => item[type]).length;
  };

  return (
    <ScrollableCard>
      {subScopes?.data?.length > 0 &&
        subScopes.data.map((subScope, i) => {
          return (
            <Card key={i} className="mb-3">
              <Card.Body>
                <Row>
                  <Col xs="12" md="8">
                    <Row xs="auto">
                      <Col xs="1">
                        <h6>{subScope.id}</h6>
                      </Col>
                      <Col xs="11" md="3">
                        <h6>{subScope.name}</h6>
                      </Col>
                      <Col xs="12" md="5">
                        <Row>
                          <Col xs="4">
                            <h6>
                              Onay kutusu
                              {questionSize(subScope, "subAdjectives")}
                            </h6>
                          </Col>
                          <Col xs="4">
                            <h6>Kutuplu {questionSize(subScope, "subPolared")}</h6>
                          </Col>
                          <Col xs="4">
                            <h6>Likert {questionSize(subScope, "subLikert")}</h6>
                          </Col>
                        </Row>
                      </Col>

                      {subScope.isManipulation && (
                        <Col>
                          <Badge bg="info">{t("manipulation")}</Badge>
                        </Col>
                      )}
                      {subScope.isConsistency && (
                        <Col>
                          <Badge bg="warning">{t("consistency")}</Badge>
                        </Col>
                      )}
                    </Row>
                  </Col>
                  <Col xs="12" md="2">
                    <Row xs="auto">
                      <Col>
                        <Dropdown>
                          <Dropdown.Toggle size="sm" variant="info" id="dropdown-basic">
                            {t("addReportElementButtonLabel")}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => openPage("/basicGraphSummary", state.scope, subScope, state.application)}
                            >
                              {t("basicGraphSummary")}
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => openPage("/basicGraphDetail", state.scope, subScope, state.application)}
                            >
                              {t("basicGraphDetail")}
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => openPage("/monotextuary", state.scope, subScope, state.application)}
                            >
                              {t("monoTextuary")}
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => openPage("/dualtextuary", state.scope, subScope, state.application)}
                            >
                              {t("dualTextuary")}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                      <Col>
                        <Dropdown>
                          <Dropdown.Toggle size="sm" variant="info" id="dropdown-basic">
                            {t("createQuestion")}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => openPage("/polar", state.scope, subScope, state.application)}>
                              {t("polar")}
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => openPage("/likert", state.scope, subScope, state.application)}
                            >
                              {t("likert")}
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => openPage("/checkbox", state.scope, subScope, state.application)}
                            >
                              {t("adjective")}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs="12" md="2">
                    <Row xs="auto" style={{ float: "right" }}>
                      <Col>
                        <Button size="sm" variant="success" onClick={() => openModal(subScope)}>
                          {t("edit")}
                        </Button>
                      </Col>
                      <Col>
                        <Button size="sm" variant="danger" onClick={() => remove(subScope.id)}>
                          <IconSvg icon="trash-can" fill="#fff" />
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          );
        })}
    </ScrollableCard>
  );
}
