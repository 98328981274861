export const GetBeforeAndAfterHitRanges = (ranges) => {
  //Returns the array of items that are before the hit item.
  let isAfterHitItem = false;

  let splitRanges = {
    hitRange: {},
    beforeHitRangeList: [],
    afterHitRangeList: [],
  };

  for (const range of ranges) {
    if (range.isHit === true) {
      splitRanges.hitRange = range;
      isAfterHitItem = true;
    } else {
      if (isAfterHitItem) {
        splitRanges.afterHitRangeList.push(range);
      } else {
        splitRanges.beforeHitRangeList.push(range);
      }
    }
  }
  return splitRanges;
};
