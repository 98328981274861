import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import BreadCrumb from "../../../Dashboard/BreadCrumb";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Card from "react-bootstrap/Card";

import UseAuthHelper from "../../../../Helpers/Auth/UseAuthHelper";
import ApiHelper from "../../../../Helpers/ApiHelper";
import Overlay from "../../../../SharedComponents/Overlay";
import Answers from "./Answers";
import TestItemList from "./TestItemList";

export function TestItem() {
  const { auth } = UseAuthHelper();
  const { state } = useLocation();
  const [showOverlay, setShowOverlay] = useState(false);
  const user = auth?.data;
  const [scopeQuestion, setScopeQuestion] = useState({
    ScopeId: state.scope.id,
    Likert: {
      QuestionText: "",
      Factor: 1,
    },
  });
  const [answers, setAnswers] = useState([]);
  const [questionImage, setQuestionImage] = useState({
    file: null,
    value: "",
  });
  const [testItems, setTestItems] = useState();

  useEffect(() => {
    RefreshScopeQuestions();
  }, []);

  let CreateScopeQuestion = () => {
    setShowOverlay(true);
    let images = [];
    if (questionImage.file) images.push(questionImage.file);
    let question = {
      Factor: scopeQuestion.Likert.Factor,
      QuestionText: scopeQuestion.Likert.QuestionText,
      ImageName: questionImage.value,
    };

    let answers_ = answers.map((ans) => {
      if (ans.file) images.push(ans.file);
      return {
        isCorrect: ans.isCorrect,
        imageName: ans.value,
        option: ans.option,
      };
    });

    const formData = new FormData();

    images.forEach((img) => {
      formData.append("Images", img);
    });

    formData.append("Question", JSON.stringify(question));
    formData.append("Answers", JSON.stringify(answers_));
    formData.append("ScopeId", scopeQuestion.ScopeId);

    ApiHelper.xmlPostFormData("question/CreateTestItem", formData, user.token, RefreshAfterCreate);
  };

  let RefreshAfterCreate = () => {
    setScopeQuestion({
      ScopeId: state.scope.id,
      Likert: {
        QuestionText: "",
        Factor: 1,
      },
    });
    setQuestionImage({
      file: null,
      value: "",
    });
    setAnswers([]);
    RefreshScopeQuestions();
    setShowOverlay(false);
  };

  let RefreshScopeQuestions = (res) => {
    setShowOverlay(true);
    ApiHelper.getAsync(
      "Question/GetTestItems",
      { scopeId: state.scope.id },
      function (result) {
        setShowOverlay(false);
        setTestItems(result.data.data);
      },
      user.token
    );
  };

  let DeleteTestItem = (question) => {
    const images = question.testItem.answers.options.map((q) => q.path).filter((n) => n);
    if (question.testItem.imagePath) images.push(question.testItem.imagePath);
    ApiHelper.getAsync(
      "question/DeleteQuestion",
      { id: question.id, imagesJson: JSON.stringify(images) },
      function () {
        RefreshScopeQuestions();
      },
      user.token
    );
  };

  let HandleCreateButtonIsDisable = () => {
    let q = scopeQuestion.Likert;
    let isCorrect = false;
    if (answers.length > 1 && answers.filter((ans) => ans.isCorrect === true).length) isCorrect = true;
    if ((q.QuestionText || questionImage.file) && q.Factor && isCorrect) return false;
    else return true;
  };

  return (
    <div className="sadmin-page-container">
      {showOverlay && <Overlay />}
      <BreadCrumb pageName="Soru" backPageName="Boyutlar" backUrl="/scope" backState={{ state: state.application }}>
        <Row>
          <Col>
            <h4> Test adı : {state.application.name}</h4>
          </Col>
          <Col>
            <h4> Boyut adı : {state.scope.name}</h4>
          </Col>
        </Row>
      </BreadCrumb>

      <Card body className="mb-3">
        <Row>
          <Col xs={12} md={6}>
            <InputGroup className="mb-3">
              <Form.Control
                disabled={questionImage.file}
                value={scopeQuestion.Likert?.QuestionText}
                placeholder="Soru metni giriniz"
                onChange={(e) =>
                  setScopeQuestion((q) => ({
                    ...q,
                    Likert: { ...q.Likert, QuestionText: e.target.value },
                  }))
                }
              />
            </InputGroup>
          </Col>
          <Col xs={12} md={3} style={{ display: "flex", paddingTop: "5px" }}>
            {questionImage.file && (
              <Button
                style={{ height: "30px", marginRight: "5px" }}
                variant="danger"
                size="sm"
                onClick={() =>
                  setQuestionImage({
                    file: null,
                    value: "",
                  })
                }
              >
                X
              </Button>
            )}
            <input
              disabled={scopeQuestion.Likert.QuestionText}
              value={questionImage.value}
              type="file"
              accept=".webp, .jpg, .png, .jpeg, .mp3, .webm"
              onChange={(e) =>
                setQuestionImage({
                  file: e.target.files[0],
                  value: e.target.value,
                })
              }
            />
          </Col>
          <Col xs={12} md={1}>
            <InputGroup size="sm" className="mb-3" style={{ paddingTop: "4px" }}>
              <InputGroup.Text id="inputGroup-sizing-sm">K</InputGroup.Text>
              <Form.Control
                type="number"
                value={scopeQuestion.Likert?.Factor}
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                onChange={(e) =>
                  setScopeQuestion((q) => ({
                    ...q,
                    Likert: { ...q.Likert, Factor: e.target.value },
                  }))
                }
              />
            </InputGroup>
          </Col>
          <Col xs={12} md={2} className="mb-3">
            <Button
              style={{ height: "100%", width: "100%" }}
              variant="primary"
              onClick={CreateScopeQuestion}
              disabled={HandleCreateButtonIsDisable()}
            >
              Oluştur
            </Button>
          </Col>
        </Row>
        <Answers setAnswers={setAnswers} answers={answers} />
      </Card>
      <TestItemList testItems={testItems} DeleteTestItem={DeleteTestItem} />
    </div>
  );
}
