import React from "react";
import { Row, Col, Container, Button } from "react-bootstrap";

export default function AnsweringPageFooter(props) {
  return (
    <>
      <div
        style={{
          position: !props.isRelative ? "fixed" : "relative",
          bottom: "0",
          right: "0",
          width: "100%",
          height: window.innerWidth > 500 ? "80px" : "60px",
          borderTop: window.innerWidth > 500 && "1px solid #CECECE",
          background: window.innerWidth > 500 && "#FFF",
          borderRadius: props.isBorderRadius && "16px",
        }}
      >
        <Container>
          <Row style={{ paddingTop: "16px" }}>
            {props.prevButtonFunction && (
              <>
                <Col xs={6}>
                  <Button onClick={() => props.prevButtonFunction(false)} className="footer-prev-button">
                    {props.prevButtonText}
                  </Button>
                </Col>
                <Col xs={6}>
                  <Button
                    variant="warning"
                    onClick={() => props.nextButtonFunction(false)}
                    className="footer-next-button"
                    disabled={props.isNextDisabled}
                  >
                    {props.nextButtonText}
                  </Button>
                </Col>
              </>
            )}
            {props.onClickFunction && (
              <Col
                md={window.innerWidth > 500 ? { span: 3, offset: 9 } : { span: 4, offset: 4 }}
                onMouseDown={() => props.onMouseDownFunction && props.onMouseDownFunction(true)}
              >
                <Button
                  className={"footer-next-button animate__animated   animate__fadeInUp  " + props.buttonClassName}
                  onClick={() => props.onClickFunction(props.data)}
                  disabled={props.isDisabled}
                  variant="warning"
                >
                  {props.buttonText}
                </Button>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
}
