import React, { useEffect, useState } from "react";
import UseAuthHelper from "../../../../Helpers/Auth/UseAuthHelper";
import { ResultFilter } from "./ResultFilter";
import { ResultTable } from "./ResultTable";
import ApiHelper from "../../../../Helpers/ApiHelper";
import Overlay from "../../../../SharedComponents/Overlay";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);
export function TicketResults() {
  const { auth } = UseAuthHelper();
  const user = auth?.data;
  useEffect(() => {
    GetTicketResultsAndSet();
  }, []);
  const [overlayVisible, setOverlayVisible] = useState(true);
  const [tickets, setTickets] = useState([]);

  let GetTicketResultsAndSet = () => {
    setOverlayVisible(true);
    ApiHelper.getAsync(
      "ticket/GetTicketResultsByUserId",
      { userId: user.userId },
      function (result) {
        setOverlayVisible(false);
        if (result.data.statusCode === 1) {
          setTickets(result.data.data);
        } else {
          MySwal.fire({
            title: <strong>Hata</strong>,
            html: <i>{result.data.message}</i>,
            icon: "error",
          });
        }
      },
      user.token
    );
  };

  const HandleShowingFilteredTicketResults = (ticketResults) => {
    setTickets(ticketResults);
  };

  const CleanSearch = () => {
    GetTicketResultsAndSet();
  };

  return (
    <div className={"sadmin-page-container"}>
      {overlayVisible && <Overlay text="Son 50 sonuç listeleniyor" />}
      <ResultFilter user={user} Show={HandleShowingFilteredTicketResults} Clean={CleanSearch} />
      <ResultTable tickets={tickets} />
    </div>
  );
}
