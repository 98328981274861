import axios from "axios";
import { BaseApiUrl } from "../Helpers/ApiHelper";

export default class HttpClient {
  constructor(baseURL) {
    this.baseURL = BaseApiUrl;
  }

  async makeRequest(method, url, param, data, headers, eAlert, sAlert) {
    try {
      const response = await axios({
        method,
        url: this.baseURL + url,
        data,
        params: param,
        headers,
      });

      if (response.data.statusCode === 0 && eAlert) alert(response.data.data);
      else if (response.data.statusCode === 1 && sAlert) alert(response.data.data);

      return response.data;
    } catch (err) {
      if (err.response?.status === 401) {
        localStorage.clear();
        window.location.reload(true);
      }
      throw err;
    }
  }
}
