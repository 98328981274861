import React from "react";
import QRCode from "react-qr-code";

export default function CreateQR({ size, value }) {
  return (
    <QRCode
      size={size}
      style={{ height: "auto", maxWidth: "100%", width: "100%" }}
      value={value}
      viewBox={`0 0 256 256`}
    />
  );
}
