import React from "react";
import ScrollableCard from "../../../SharedComponents/ScrollableCard";
import SelectWrapUpReportItemDesign from "./ReportItems/SelectWrapUpReportItemDesign";
import MonoSpace from "./ReportItems/HtmlElements/MonoSpace";
import DualSpace from "./ReportItems/HtmlElements/DualSpace";
import ImageSpace from "./ReportItems/HtmlElements/ImageSpace";
import { useSelector } from "react-redux";

export default function ReportItemMapper({ calculatedScheme, type }) {
  const wrapUpState = useSelector((state) => state.wrapUp);

  const HandleReportItemMappingAndFiltering = (item) => {
    for (const [key, value] of Object.entries(wrapUpState)) {
      if (item.htmlEl === true && value) {
        // html mi değil mi?
        if (item.reportType === key || item.reportType === "All") {
          // rapor tipimizle uyuşuyor mu?

          switch (item.componentName) {
            case "EmptySpace":
              return;
            case "MonoSpace":
              return <MonoSpace key={Math.random()} item={item} />;
            case "DualSpace":
              return <DualSpace key={Math.random()} item={item} />;
            case "ImageSpace":
              return <ImageSpace key={Math.random()} item={item} />;
            default:
              return;
          }
        }
      } else if (
        (item.componentName.includes(key) && value) ||
        item.componentName === "AnsweringTendencyEntity" ||
        item.componentName === "TestScaleEntity"
      )
        return <SelectWrapUpReportItemDesign item={item} type={type} />;
    }
  };

  return (
    <ScrollableCard>
      <div className="animate__animated animate__fadeIn ">
        {calculatedScheme && calculatedScheme.map((x) => HandleReportItemMappingAndFiltering(x))}
      </div>
    </ScrollableCard>
  );
}
