import React from "react";
import Image from "react-bootstrap/Image";
import ApiHelper from "../../../../Helpers/ApiHelper";

export default function InfoSection({ imageName }) {
  let background1 = {
    position: "relative",
    height: "1123px",
    left: 0,
    top: 0,
    width: "792px",
    zIndex: "-100",
  };

  return <Image style={background1} crossOrigin="anonymous" src={ApiHelper.baseURL + "wwwroot/info/" + imageName} />;
}
