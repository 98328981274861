import React from "react";
import { Col, ProgressBar, Row } from "react-bootstrap";

const TestScaleDesign_1 = ({ item, isSub }) => {
  let elementScore = item.normScore ? Number(item.normScore) : item.score;

  return (
    <Row style={{ height: Number(item.height) }}>
      <div
        className="mb-5 mt-5"
        style={{
          padding: "20px 20px",
          background: "rgb(247, 247, 244)",
          borderRadius: "20px",
        }}
      >
        <Row className={"text-center mb-3"}>
          <h3>Test Skoru</h3>
        </Row>
        <Row>
          <Col xs={3} className={"align-content-center"}>
            <Row>
              <Col className={"align-content-center test-scale-name-pdf"}>Test Skoru</Col>
              <Col xs={"auto"} style={{ display: "inline-block", float: "right" }} className={"align-content-center"}>
                <div className={"_main-scope-score-pdf text-white "}>{elementScore}</div>
              </Col>
            </Row>
          </Col>
          <Col xs={9} className={"align-content-center"}>
            <Row>
              {item.scales.map((range, i) => {
                if (range.isHit) {
                  return (
                    <Col className={"hit-scale-style-test-pdf p-0"}>
                      <Row className={"w-100 h-100"}>
                        <ProgressBar
                          className={"h-100 w-100 p-0"}
                          striped
                          variant="warning"
                          now={100}
                          label={range.text}
                        />
                      </Row>
                    </Col>
                  );
                } else {
                  return (
                    <Col key={i + "-" + range.id + "scopeScale"} className={"scale-style-wrap-up"}>
                      {range.text}
                    </Col>
                  );
                }
              })}
            </Row>
          </Col>
        </Row>
      </div>
    </Row>
  );
};
export default TestScaleDesign_1;
