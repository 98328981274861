import React from "react";
import { Dna, MagnifyingGlass, ProgressBar } from "react-loader-spinner";

export default function Overlay(props) {
  if (props.magnify)
    return (
      <div className="overlay-low-opacity">
        <div className="overlay-content">
          <MagnifyingGlass
            visible={true}
            height="180"
            width="180"
            ariaLabel="MagnifyingGlass-loading"
            wrapperStyle={{}}
            wrapperClass="MagnifyingGlass-wrapper"
            glassColor="#c0efff"
            color="#e15b64"
          />
          <h1> {props.text}</h1>
        </div>
      </div>
    );

  if (props.dna)
    return (
      <div className="overlay">
        <div className="overlay-content">
          <Dna
            visible={true}
            height="180"
            width="180"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
          <h1> {props.text}</h1>
        </div>
      </div>
    );

  return (
    <div className="overlay  ">
      <div className="overlay-content">
        <ProgressBar
          height="80"
          width="80"
          ariaLabel="progress-bar-loading"
          wrapperStyle={{}}
          wrapperClass="progress-bar-wrapper"
          borderColor="#2c3034"
          barColor="#0d6efd"
        />
        <h1> {props.text}</h1>
      </div>
    </div>
  );
}
