import React from "react";
import { Col, ProgressBar, Row } from "react-bootstrap";

export default function Summary_LoadingBar({ item, isSub }) {
  let elementScore = item.normScore ? Number(item.normScore) : item.score;

  return (
    <Row className="mb-3" style={{ height: Number(item.height) }}>
      <Col>
        <Row>
          <Col xs={3} className={"align-content-center"}>
            <Row>
              <Col className={isSub ? "_sub-scope-names-pdf" : "_main-scope-name-pdf"}>{item.name}</Col>
              <Col xs={"auto"} style={{ display: "inline-block", float: "right" }}>
                <div className={!isSub ? "_main-scope-score-pdf" : "_sub-scope-score-pdf"}>{elementScore}</div>
              </Col>
            </Row>
          </Col>
          <Col xs={9} className={"align-content-center"}>
            <ProgressBar variant={isSub ? "sub-scope" : "main-scope"} now={elementScore} label={`${elementScore}%`} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
