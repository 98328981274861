import React, { useEffect, useState } from "react";
import UseAuthHelper from "../../../../Helpers/Auth/UseAuthHelper";
import { Row } from "react-bootstrap";
import { AppCard } from "./AppCard";
import ApiHelper from "../../../../Helpers/ApiHelper";
import Overlay from "../../../../SharedComponents/Overlay";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";

const MySwal = withReactContent(Swal);

export function UserDash() {
  let navigate = useNavigate();
  const { auth } = UseAuthHelper();
  const user = auth?.data;
  if (!user.demography) navigate("/demography");

  const [ticketsAndCompanyInfo, setTicketsAndCompanyInfo] = useState(null);
  const [isOverlayVisible, setIsOverlayVisible] = useState(true);

  useEffect(() => {
    document.addEventListener("visibilitychange", function () {
      if (!document.hidden) this.location.reload();
    });
    if (ticketsAndCompanyInfo === null) GetUserTickets();
  }, []);

  const GetUserTickets = () => {
    window.isTicketsAsked = true;
    ApiHelper.getAsync(
      "ticket/GetTicketsByUserId",
      { userId: user.userId },
      function (result) {
        setIsOverlayVisible(false);

        if (result.data.statusCode === 1) {
          setTicketsAndCompanyInfo(result.data.data);
        } else {
          MySwal.fire({
            title: <strong>Hata</strong>,
            html: <i>{result.data.message}</i>,
            icon: "error",
          });
        }
      },
      user.token
    );
  };

  return (
    <div className="inner-page-container">
      {isOverlayVisible && <Overlay />}
      <Row>
        {ticketsAndCompanyInfo?.tickets?.map((ticket, i) => (
          <AppCard ticket={ticket} companyInfo={ticketsAndCompanyInfo?.companyInfo} key={i} />
        ))}
      </Row>
    </div>
  );
}
