import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import { HandleGroupingResultImageForCalculation } from "../../../Helpers/ResultImagesGroupingHelper";

import ApiHelper from "../../../Helpers/ApiHelper";
import ReportItemMapper from "./ReportItemMapper";
import Overlay from "../../../SharedComponents/Overlay";

import "../Styles/index.css";
import "../Styles/WrapUp.css";
import "animate.css";
import WrapUpHeader from "./WrapUpHeader";
import TestPdfButton from "../../PdfDownloadButton/Components/TestPdfButton";

export default function TestWrapUp() {
  const { state } = useLocation();
  const { ticketResultId } = useParams();
  const { demoResultId } = useParams();
  const [_scheme_, setScheme] = useState([]);
  const [appData, setAppData] = useState({});
  const [demography, setDemography] = useState();

  const [showOverlay, setShowOverlay] = useState(true);

  useEffect(() => {
    if (state) CalculateTest(state);
    else if (demoResultId) {
      ApiHelper.getAsync(
        "user/GetDemoResult",
        { demoResultId },
        function (response) {
          if (response.data.statusCode) CalculateTest(response.data.data);
          else alert("bir hata oluştu"); //todo burada hatayi handle et , guzek bir alert kutusu goster gerekirse sayfayi kapat
        },
        null
      );
    } else if (ticketResultId) {
      //ticketResult getir sonra role gore pdf scheme getir
    } else {
      setShowOverlay(false);
      alert("problem");
    }
  }, []);

  let CalculateTest = (result) => {
    const postObj = HandleGroupingResultImageForCalculation(result, true);
    setDemography({
      name: result.name,
      email: result.email,
      department: result.department,
      faculty: result.faculty,
      school: result.school,
      birthYear: result.birthYear,
      gender: result.gender,
    });

    ApiHelper.postAsync(
      "user/CalculateTest",
      postObj,
      function (response) {
        MapTestReportSchemeWithCalculation(response.data.data);
      },
      null
    );
  };

  const CreateQrUrl = () => {
    let qrURL = "";
    if (demoResultId) qrURL = window.location.origin + "/#/tdemoResult/" + demoResultId;
    else if (ticketResultId) qrURL = window.location.origin + "/#/tResult/" + ticketResultId;
    return qrURL;
  };

  let MapTestReportSchemeWithCalculation = (data) => {
    let appData = data.appData;
    setAppData(appData);
    let calculatedScopes = data?.calculation?.calculatedScopes;
    let testScore = data.calculation.testScore;
    let scheme = appData.adminWrapUpJson && JSON.parse(appData.adminWrapUpJson);

    scheme.forEach((reportItem) => {
      if (reportItem.htmlEl) return;
      else if (reportItem.scales) {
        reportItem.score = testScore;
      } else reportItem.score = calculatedScopes.find((x) => x.scopeId === reportItem.scopeId)?.score;
    });

    setScheme(scheme);
    setShowOverlay(false);
  };

  return (
    <Container>
      {showOverlay && <Overlay text="Pdf Dosyası Hazırlanıyor" />}

      <WrapUpHeader userDemography={demography} applicationName={appData?.name} CreateQrUrl={CreateQrUrl}>
        <TestPdfButton demoResultId={demoResultId} ticketResultId={ticketResultId} />
      </WrapUpHeader>

      <ReportItemMapper calculatedScheme={_scheme_} type={"test"} />
    </Container>
  );
}
