import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Button, Card, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import ApiHelper from "../../../../Helpers/ApiHelper";
import ScrollableCard from "../../../../SharedComponents/ScrollableCard";
import UseAuthHelper from "../../../../Helpers/Auth/UseAuthHelper";
import IconSvg from "../../../../App/AppMenu/IconSvg";
import { useLocation, useNavigate } from "react-router-dom";
import BreadCrumb from "../../../../Components/Dashboard/BreadCrumb";

export default function ApplicationInfo() {
  const { auth } = UseAuthHelper();
  const { state } = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const application = state;

  const user = auth?.data;
  const [infoTitle, setInfoTitle] = useState(application?.info?.infoTitle);
  const [editorState, setEditorState] = useState();

  const [pdfCoverImageName, setPdfCoverImageName] = useState({
    file: null,
    value: "",
  });

  const [pdfInfoImageName, setPdfInfoImageName] = useState({
    file: null,
    value: "",
  });
  const [iconImageName, setIconImageName] = useState({
    file: null,
    value: "",
  });

  useEffect(() => {
    HandleEditorState(application?.info?.infoHtml);
    setInfoTitle(application?.info?.infoTitle);
  }, []);

  const HandleEditorState = (infoHtml) => {
    const prevHtml = infoHtml ? infoHtml : "<p> Uygulama bilgilendirme sayfası içeriğini giriniz</p>";

    const contentBlock = htmlToDraft(prevHtml);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    setEditorState(EditorState.createWithContent(contentState));
  };

  const OnEditorStateChange = (state) => {
    setEditorState(state);
  };

  const ResfreshAfterUpdate = async () => {
    navigate(application.isTest ? "/test" : "/inventory");
  };

  const UpdateInventoryInfo = () => {
    const formData = new FormData();

    formData.append("pdfCoverImage", pdfCoverImageName.file);
    formData.append("pdfInfoImage", pdfInfoImageName.file);
    formData.append("iconImage", iconImageName.file);
    formData.append("infoHtml", draftToHtml(convertToRaw(editorState?.getCurrentContent())));
    formData.append("applicationId", application?.id);
    formData.append("infoTitle", infoTitle);
    formData.append("applicationType", application.applicationType);
    ApiHelper.xmlPostFormData("application/UpsertApplicationInfo", formData, user.token, ResfreshAfterUpdate);
  };

  const DeleteImage = (imageName, type) => {
    ApiHelper.getAsync(
      "application/deleteInfoImage",
      { imageName, type, infoId: application.info.id },
      function (result) {
        if (!result.data.statusCode) alert("hata olustu");
        else {
          ResfreshAfterUpdate();
        }
      },
      user.token
    );
  };

  return (
    <div className="sadmin-page-container">
      <BreadCrumb
        pageName={t("addApplicationInfo")}
        backPageName={application.isTest ? t("tests") : t("inventories")}
        backUrl={application.isTest ? "/test" : "/inventory"}
      >
        <Row>
          <Col xs="11">
            <h3>
              {application.isTest ? "Test" : "Envanter"} adı : {application.name}
            </h3>
          </Col>
          <Col xs="1">
            <Button variant="primary" onClick={UpdateInventoryInfo} style={{ width: "100%" }}>
              {t("save")}
            </Button>
          </Col>
        </Row>
      </BreadCrumb>
      <Card body>
        <ScrollableCard>
          <Container>
            <Row className="mb-3">
              <Col>
                {application?.info?.iconImageName ? (
                  <Button
                    size="sm"
                    variant="danger"
                    style={{ margin: "10px" }}
                    onClick={() => DeleteImage(application?.info?.iconImageName, "icon")}
                  >
                    <IconSvg icon="trash-can" fill="#fff" />
                  </Button>
                ) : (
                  <input
                    value={iconImageName.value}
                    type="file"
                    accept="image/png, image/jpeg, image/webp"
                    onChange={(e) =>
                      setIconImageName({
                        file: e.target.files[0],
                        value: e.target.value,
                      })
                    }
                  />
                )}

                <img
                  src={ApiHelper.baseURL + "wwwroot/info/" + application?.info?.iconImageName}
                  height="55"
                  width="55"
                />
              </Col>
            </Row>
            <Row className="mb-5">
              <Col>
                {application?.info?.pdfCoverImageName ? (
                  <Button
                    size="sm"
                    variant="danger"
                    style={{ margin: "10px" }}
                    onClick={() => DeleteImage(application?.info?.pdfCoverImageName, "cover")}
                  >
                    <IconSvg icon="trash-can" fill="#fff" />
                  </Button>
                ) : (
                  <input
                    value={pdfCoverImageName.value}
                    type="file"
                    accept="image/png, image/jpeg, image/webp"
                    onChange={(e) =>
                      setPdfCoverImageName({
                        file: e.target.files[0],
                        value: e.target.value,
                      })
                    }
                  />
                )}

                <img
                  src={ApiHelper.baseURL + "wwwroot/info/" + application?.info?.pdfCoverImageName}
                  height="297"
                  width="210"
                />
              </Col>
              <Col>
                {application?.info?.pdfInfoImageName ? (
                  <Button
                    size="sm"
                    variant="danger"
                    style={{ margin: "10px" }}
                    onClick={() => DeleteImage(application?.info?.pdfInfoImageName, "info")}
                  >
                    <IconSvg icon="trash-can" fill="#fff" />
                  </Button>
                ) : (
                  <input
                    value={pdfInfoImageName.value}
                    type="file"
                    accept="image/png, image/jpeg, image/webp"
                    onChange={(e) =>
                      setPdfInfoImageName({
                        file: e.target.files[0],
                        value: e.target.value,
                      })
                    }
                  />
                )}

                <img
                  src={ApiHelper.baseURL + "wwwroot/info/" + application?.info?.pdfInfoImageName}
                  height="297"
                  width="210"
                />
              </Col>
            </Row>
            <InputGroup className="mb-3">
              <InputGroup.Text>{t("answeringPageInfoInputLabel")}</InputGroup.Text>
              <Form.Control
                placeholder="Bilgilendirme sayfası başlığı giriniz"
                value={infoTitle ? infoTitle : application?.info?.infoTitle}
                onChange={(e) => setInfoTitle(e.target.value)}
              />
            </InputGroup>

            <Editor
              editorState={editorState ? editorState : HandleEditorState(application?.info?.infoHtml)}
              onEditorStateChange={OnEditorStateChange}
            />
          </Container>
        </ScrollableCard>
      </Card>
    </div>
  );
}
