// import { useSelector } from "react-redux";
import CoverSection from "../PdfReportItems/CoverSection";
import InfoSection from "../PdfReportItems/InfoSection";
import PdfPage from "./PdfPage";
import { useEffect } from "react";

const WholePdfFile = ({ StartDownloadPdf, scheme, appData, demography, type, qrURL }) => {
  //TODO: get this from backend, not redux.
  // let scheme = useSelector((state) => state.reportScheme.inventory.reportScheme);

  useEffect(() => {
    StartDownloadPdf();
  }, []);

  return (
    <div>
      {appData.pdfCoverImageName && (
        <CoverSection
          imageName={appData.pdfCoverImageName}
          appName={appData.name}
          demography={demography}
          qrURL={qrURL}
        />
      )}
      {appData.pdfInfoImageName && <InfoSection imageName={appData.pdfInfoImageName} />}
      {scheme.map((page, index) => {
        return (
          <PdfPage pageNumber={index + 1} appName={appData.name} personName={demography.name} type={type}>
            {page}
          </PdfPage>
        );
      })}
    </div>
  );
};

export default WholePdfFile;
