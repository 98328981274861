import Col from "react-bootstrap/Col";
import React from "react";
import { useDispatch } from "react-redux";

import IconSvg from "../../../../../../App/AppMenu/IconSvg";
import { getApplicationBackup } from "../../../Store/Thunk";

export default function BackUpButton({ inventory }) {
  const dispatch = useDispatch();

  const Download = () => {
    dispatch(getApplicationBackup({ id: inventory.id, name: inventory.name }));
  };

  return (
    <Col className="show-cursor" onClick={Download}>
      <IconSvg icon="download" />
    </Col>
  );
}
