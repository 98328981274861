import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";

import DeleteTest from "../ActionsButton/DeleteTest";
import Navigate from "../ActionsButton/Navigate";
import OpenTestDemo from "../ActionsButton/OpenTestDemo";
import OpenUpdateTestModal from "../ActionsButton/OpenUpdateTestModal";
import OpenInfoModal from "../ActionsButton/OpenInfoModal";
import { useTranslation } from "react-i18next";

const TestListRowActionButtons = React.memo(({ test }) => {
  const { t } = useTranslation();

  return (
    <>
      <Col xs="6" md="6">
        <Row xs="auto" className="justify-content-center">
          <Col>
            <Navigate test={test} path="/scope">
              {t("scopes")}
            </Navigate>
          </Col>
          <Col>
            <Navigate test={test} path="/reorder">
              {t("sortBy")}
            </Navigate>
          </Col>
          <Col>
            <Navigate test={test} path="/info">
              {t("applicationInfo")}
            </Navigate>
          </Col>
          <Col>
            <Navigate test={test} path="/scheme">
              {t("scheme")}
            </Navigate>
          </Col>
          <Col>
            <OpenTestDemo test={test} />
          </Col>
        </Row>
      </Col>
      <Col xs="6" md="2">
        <Row xs="auto" className="justify-content-md-end">
          <Col>
            <OpenUpdateTestModal test={test} />
          </Col>
          <Col>
            <DeleteTest test={test} />
          </Col>
        </Row>
      </Col>
    </>
  );
});

export default TestListRowActionButtons;
