import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function Navigate({ path, test, children }) {
  const navigate = useNavigate();

  const handleNavigate = () => navigate(path, { state: test });

  return (
    <Button size="sm" variant="primary" onClick={handleNavigate}>
      {children}
    </Button>
  );
}
