import React from "react";
import Row from "react-bootstrap/Row";
import BackUpButton from "./ActionsButton/BackUpButton";
import Col from "react-bootstrap/Col";
import UpdateInventoryButton from "./ActionsButton/UpdateInventoryButton";
import DeleteButton from "./ActionsButton/DeleteButton";

const RowActions = React.memo(({ inventory }) => {
  return (
    <Col xs="2" md="2">
      <Row xs="auto" className="justify-content-md-end">
        <BackUpButton inventory={inventory} />
        <Col>
          <UpdateInventoryButton application={inventory} />
        </Col>
        <Col>
          <DeleteButton inventory={inventory} />
        </Col>
      </Row>
    </Col>
  );
});
export default RowActions;
