import React from "react";
import { Col, ProgressBar, Row } from "react-bootstrap";

export default function TestScaleDesign_1({ item, isSub }) {
  return (
    <Row
      className="mb-5 mt-5 justify-content-center mx-0"
      style={{
        padding: "20px 50px 50px 50px",
        background: "white",
        borderRadius: "20px",
      }}
    >
      <Col>
        <Row className={"text-center mb-3"}>
          <div>
            <h1>Test Skoru</h1>
          </div>
        </Row>
        <Row className="mb-3">
          <Col xs={3} className={"align-content-center"}>
            <Row>
              <Col className={"align-content-center test-scale-name-wrap-up"}>Test Skoru</Col>
              <Col style={{ display: "inline-block", float: "right" }}>
                <div className={"main-scope-score-wrap-up text-white "}>{item.score}</div>
              </Col>
            </Row>
          </Col>
          <Col xs={9} className={"align-content-center"}>
            <Row>
              {item.scales.map((range, i) => {
                if (range.isHit) {
                  return (
                    <Col className={"hit-scale-style-test-wrap-up p-0"}>
                      <Row className={"h-100 w-100"}>
                        <ProgressBar
                          className={"h-100 w-100 p-0"}
                          animated
                          variant="warning"
                          now={100}
                          label={range.text}
                        />
                      </Row>
                    </Col>
                  );
                } else {
                  return (
                    <Col key={i + "-" + range.id + "scopeScale"} className={"scale-style-wrap-up"}>
                      {range.text}
                    </Col>
                  );
                }
              })}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
