import React from "react";
import { Col, Row } from "react-bootstrap";

export default function Detail_4({ item, isSub }) {
  let hitRange = item.scale.ranges.find((range) => range.isHit === true);
  let elementScore = item.normScore ? Number(item.normScore) : item.score;
  return (
    <Row
      className={"justify-content-center align-content-start"}
      style={{ height: Number(item?.height), marginBottom: "6px" }}
    >
      {!isSub && (
        <Row className={"justify-content-center jakarta-sans"} style={{ fontSize: 15, fontWeight: 800 }}>
          {item.scopeName}
        </Row>
      )}

      <div className={"py-3 rounded-4 px-4"} style={{ backgroundColor: isSub ? "white" : "#0048aa" }}>
        <Row className={"justify-content-center"}>
          {isSub && (
            <Row className={"justify-content-center "}>
              <Col xs={"auto"} className={"detail-scope-name-blurred-smaller-pdf mb-1"}>
                {item.scopeName}
              </Col>
            </Row>
          )}
        </Row>
        <Row className={"justify-content-between"}>
          <Col xs={4} className={"_scale-detail-text-pdf-new"}>
            <div style={{ color: !isSub ? "white" : "" }} dangerouslySetInnerHTML={{ __html: item.lowSideText }}></div>
          </Col>

          <Col xs={4} className={"px-3"}>
            <Row className={"justify-content-center"}>
              <Col xs={"auto"}>
                <Row className={"justify-content-center"}>
                  {item.scale.isHtml ? (
                    <div
                      className="z-1 _flex-center"
                      style={{
                        backgroundColor: isSub ? "#007aff" : "#fff5ce",
                        color: isSub ? "white" : "#00428a",
                      }}
                      dangerouslySetInnerHTML={{ __html: item.monoSpaceHtml }}
                    />
                  ) : (
                    <div
                      className={"z-1 detail-hit-range-name-above-pdf px-1"}
                      style={{
                        backgroundColor: isSub ? "#007aff" : "#fff5ce",
                        color: isSub ? "white" : "#00428a",
                      }}
                    >
                      {hitRange?.text}
                    </div>
                  )}
                  <div className={"px-0 position-relative justify-content-center"} style={{ height: 6, width: 10 }}>
                    <div
                      className={"z-0 rotated-div-cube position-absolute"}
                      style={{ top: -5, height: 10, width: 10, backgroundColor: isSub ? "#007aff" : "#fff5ce" }}
                    ></div>
                  </div>
                </Row>
              </Col>
            </Row>

            <Row
              className={"justify-content-between round-30px border border-2"}
              style={{ borderColor: "#A6D1FF", padding: "0px 5px " }}
            >
              {item.scale.ranges.map((range, i) => {
                if (range.isHit === true) {
                  return (
                    <Col xs={"auto"} className={"align-content-center"}>
                      <Row className={"hit-scale-style-variable-opacity-pdf-round-smaller _flex-center"}>
                        {elementScore / 10}
                      </Row>
                    </Col>
                  );
                } else {
                  return (
                    <Col xs={"auto"} className={"align-content-center p-0"}>
                      <div
                        style={{
                          backgroundColor: "white",
                          width: "fit-content",
                          height: "fit-content",
                          borderRadius: "50%",
                          padding: 0,
                        }}
                      >
                        <div
                          className={"scale-style-variable-opacity-pdf-round-smaller"}
                          style={{ opacity: (i + 1) * (1 / item.scale.ranges.length) }}
                        ></div>
                      </div>
                    </Col>
                  );
                }
              })}
            </Row>
          </Col>

          <Col xs={4} className={"_scale-detail-text-pdf-new"}>
            <div style={{ color: !isSub ? "white" : "" }} dangerouslySetInnerHTML={{ __html: item.highSideText }}></div>
          </Col>
        </Row>
      </div>
    </Row>
  );
}
