export const InventoryApiUrls = {
  getInventories: "inventory/GetInventories",
  upsertLeanParameters: "test/UpsertLeanParameters",
  createInventory: "inventory/CreateInventory",
  updateInventory: "inventory/UpdateInventory",
  deleteInventory: "inventory/DeleteInventory",
  upsertLeanParameters: "inventory/UpsertLeanParameters",
  getInventoryBackup: "inventory/GetInventoryBackup",
  uploadInventoryBackUp: "inventory/UploadInventoryBackUp",
};

export default class InventoryAPI {
  constructor(httpsClient, cache) {
    this.httpsClient = httpsClient;
    this.cache = cache;
  }

  async getInventories(data, token) {
    return this.get(InventoryApiUrls.getInventories, data, token);
  }

  async upsertLeanParameters(data, token) {
    return this.post(InventoryApiUrls.upsertLeanParameters, data, token);
  }

  async createInventory(data, token) {
    return this.post(InventoryApiUrls.createInventory, data, token);
  }

  async updateInventory(data, token) {
    return this.post(InventoryApiUrls.updateInventory, data, token);
  }

  async deleteInventory(data, token) {
    return this.get(InventoryApiUrls.deleteInventory, data, token);
  }

  async uploadInventoryBackUp(data, token) {
    return this.post(InventoryApiUrls.uploadInventoryBackUp, data, token);
  }

  async getInventoryBackup(data, token) {
    return await this.get(InventoryApiUrls.getInventoryBackup, data, token);
  }

  async upsertLeanParameters(data, token) {
    return this.post(InventoryApiUrls.upsertLeanParameters, data, token);
  }

  async get(url, data, token) {
    const response = await this.httpsClient.get(url, data, token);
    return response;
  }

  async post(url, data, token) {
    this.cache.remove("tests");
    await this.httpsClient.post(url, data, token);
  }
}
