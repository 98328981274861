import React from "react";
import { Col, ProgressBar, Row } from "react-bootstrap";
import { GetBeforeAndAfterHitRanges } from "../../Calculators/RangeSplitter";

export default function Summary_2({ item, isSub }) {
  //const isHtml = item.scale.isHtml;
  let elementScore = item.normScore ? Number(item.normScore) : item.score;
  let splitRanges = GetBeforeAndAfterHitRanges(item.scale.ranges);
  let oneStep = 100 / item.scale.ranges.length;
  let filledPart = elementScore % oneStep;

  return (
    <Row className={"mb-3 mx-0"} style={{ marginBottom: "6px" }}>
      <Col className={"align-content-center"} xs={3}>
        <Row
          className={"justify-content-end me-5 " + (isSub ? "sub-name-style-wrap-up-thin" : "name-style-wrap-up-thin")}
        >
          {item.name}
        </Row>
      </Col>
      <Col className={"align-content-center"}>
        <Row className={"justify-content-center "}>
          <div className={"_flex-center " + (isSub ? "sub-score-style-wrap-up-thin" : "score-style-wrap-up-thin")}>
            {elementScore}
          </div>
        </Row>
      </Col>

      <Col xs={8} className={"align-content-center"}>
        <Row>
          {splitRanges.beforeHitRangeList.map((range, i) => {
            return (
              <Col className={" px-0 align-content-center"}>
                <ProgressBar
                  className={isSub ? "sub-scale-style-thin" : "scale-style-thin"}
                  style={{ height: "12px", margin: "0 2px" }}
                  now={100}
                ></ProgressBar>
              </Col>
            );
          })}
          <Col className={" p-0 align-content-center"}>
            <ProgressBar
              className={isSub ? "sub-scale-style-thin" : "scale-style-thin"}
              style={{ height: "12px", margin: "0 2px" }}
              now={filledPart}
              min={0}
              max={oneStep}
            ></ProgressBar>
          </Col>
          {splitRanges.afterHitRangeList.map((range, i) => {
            return (
              <Col className={" px-0 align-content-center"}>
                <ProgressBar
                  className={isSub ? "sub-scale-style-thin" : "scale-style-thin"}
                  style={{ height: "12px", margin: "0 2px" }}
                  now={0}
                ></ProgressBar>
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
}
