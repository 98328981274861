import React from "react";
import { Col, ProgressBar, Row } from "react-bootstrap";

export default function ParameterProgressBar({ name, score }) {
  if (score <= 0) score = 0;
  else score = Number(score).toFixed(1);
  return (
    <Row className="mb-3">
      <Col xs="4" className={"align-content-center"}>
        <Row className={"main-scope-name-pdf"}>
          <Col className="align-content-center">{name}</Col>
          <Col xs={"auto"}>{score + "%"}</Col>
        </Row>
      </Col>
      <Col xs="8" className={"align-content-center"}>
        <ProgressBar style={{ height: "25px" }} animated variant="warning" now={score} label={score + "%  " + name} />
      </Col>
    </Row>
  );
}
