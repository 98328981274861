import React, { useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { designTypes } from "../../../ReportItems/ReportItemBase/Components/DesignTypeDropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  setEntityModalInfo,
  switchFromNonVisibleToVisible,
  switchFromVisibleToNonVisible,
  updateReportSchemeState,
} from "../../Store";

export default function ReportItemEntityListRow({ entity, itemName }) {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState(entity.order);
  const [heightInputVal, setHeightInputVal] = useState(Number(entity.height));
  const visibleReportItemEntityList = useSelector((state) => state.reportScheme.visibleReportItemEntityList);
  const [reportType, setReportType] = useState("All");

  const GetKeyByValue = (value) => {
    return Object.keys(designTypes).find((key) => designTypes[key] === value);
  };

  const ChangeOrder = () => {
    let val = parseInt(inputValue);
    if (val > visibleReportItemEntityList.length || val === entity.order || val <= 0)
      return setInputValue(entity.order);
    let newList = JSON.parse(JSON.stringify(visibleReportItemEntityList));
    newList = newList.filter((x) => x.order != entity.order);
    newList.forEach((x, i) => (x.order = i + 1));

    let firstHalf = [];
    let secondHalf = [];
    newList = JSON.parse(JSON.stringify(newList));
    newList.forEach((x, i) => {
      if (i + 1 < val) firstHalf.push(x);
      else {
        x.edited = true;
        secondHalf.push(x);
      }
    });
    entity = JSON.parse(JSON.stringify(entity));
    entity.edited = true;
    firstHalf.push(entity);

    firstHalf = firstHalf.concat(secondHalf);
    let resultList = JSON.parse(JSON.stringify(firstHalf));
    resultList.forEach((x, i) => {
      x.order = i + 1;
    });

    dispatch(updateReportSchemeState({ visibleReportItemEntityList: resultList }));
  };

  const ChangeEntityHeight = () => {
    let coppiedList = JSON.parse(JSON.stringify(visibleReportItemEntityList));

    coppiedList.forEach((x, i) => {
      if (x.order == entity.order) {
        x.defaultHeight = x.defaultHeight ? x.defaultHeight : x.height;
        x.height = heightInputVal;
      }
    });

    dispatch(updateReportSchemeState({ visibleReportItemEntityList: coppiedList }));
  };

  const ChangeIsVisible = (isVisible) => {
    if (isVisible === false) {
      dispatch(
        switchFromVisibleToNonVisible({
          entity: entity,
        })
      );
    } else {
      dispatch(
        switchFromNonVisibleToVisible({
          entity: entity,
        })
      );
    }
  };

  return (
    <Row style={{ width: "100%" }}>
      <Col xs="6" md="1">
        <h6 style={{ color: "lightgray", fontSize: "14px" }}>{entity.id} </h6>
        <h6> {itemName} </h6>
        <h6 style={{ color: "lightgray", fontSize: "14px" }}>{GetKeyByValue(entity.designType)} </h6>
      </Col>
      <Col xs="6" md="4" className="flex-center">
        <h3
          className={"show-cursor darken-hover rounded p-1"}
          onClick={() => {
            dispatch(setEntityModalInfo({ isShown: true, entity: entity }));
          }}
        >
          {entity.name}
        </h3>
      </Col>
      <Col xs="6" md="1" className={"align-content-center"}></Col>
      <Col xs="6" md="3" className={"align-content-center"}>
        <Row>
          <Col>
            <InputGroup size="sm">
              <InputGroup.Text>
                <div className="report-item-change-order-button" onClick={() => ChangeEntityHeight()}>
                  Kaydet
                </div>
              </InputGroup.Text>
              <Form.Control
                size="sm"
                type="number"
                min="1"
                value={heightInputVal}
                onChange={(e) => setHeightInputVal(e.target.value)}
              />
            </InputGroup>
            <h6 className="flex-center" style={{ color: "lightgray", fontSize: "14px", paddingTop: "5px" }}>
              {entity.defaultHeight}
            </h6>
          </Col>
          <Col>
            <h5>{entity.scopeName}</h5>
            <h6 style={{ color: "lightgray" }}>{entity.subScopeName}</h6>
          </Col>
        </Row>
      </Col>
      <Col xs="6" md="1" className="flex-center">
        <h4
          style={{
            color: entity.orderedBefore && !entity.edited ? "green" : "red",
          }}
        >
          {entity.order}
        </h4>
      </Col>

      <Col xs="6" md="1">
        <div className="report-item-change-order-button" onClick={() => ChangeOrder()}>
          Değiştir
        </div>
        <Form.Control
          type="number"
          min="1"
          size="sm"
          value={inputValue}
          step="1"
          onChange={(e) => setInputValue(e.target.value)}
        />
      </Col>
      <Col xs="6" md="1" className={"align-content-center"}>
        <Row className={"justify-content-end"}>
          <Form.Check reverse>
            <Form.Check.Input
              onChange={(event) => {
                ChangeIsVisible(event.target.checked);
              }}
              defaultChecked={entity.isVisible}
              className={"rounded-circle p-3 m-0 show-cursor"}
            ></Form.Check.Input>
          </Form.Check>
        </Row>
      </Col>
    </Row>
  );
}
