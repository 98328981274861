import { createAsyncThunk } from "@reduxjs/toolkit";
import CompanyUsersAPI, { api } from "../API/CompanyUsersAPI";

import LocalStorage from "../../../../Services/Storage/LocalStorage";
import StorageModel from "../../../../Models/StorageModel";
import HttpsClient from "../../../../Services/HttpsClient";
import ApiHelper from "../../../../Services/ApiHelper";
import Cache from "../../../../Services/Cache";

const storage = new LocalStorage();
const storageModel = new StorageModel(storage);
const cache = new Cache(storageModel);

const httpsClient = new HttpsClient();
const apiHelper = new ApiHelper(httpsClient);
const companyUsersAPI = new CompanyUsersAPI(apiHelper, cache);

export const getCompanyUsers = createAsyncThunk(api.getCompanyUsers, async (payload, { getState, dispatch }) => {
  const state = getState();
  const token = state.auth.user.token;
  if (!payload) {
    payload = { companyId: state.companyUser.companyId };
  }

  return await companyUsersAPI.getCompanyUsers(payload, token);
});

export const getCompanyUserByCompanyUserId = createAsyncThunk(
  api.getCompanyUserByCompanyUserId,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;

    return await companyUsersAPI.getCompanyUserByCompanyUserId(payload, token);
  }
);

export const createCompanyUser = createAsyncThunk(api.createCompanyUser, async (payload, { getState, dispatch }) => {
  const state = getState();
  const token = state.auth.user.token;
  const response = await companyUsersAPI.createCompanyUser(payload, token, true, true);

  dispatch(getCompanyUsers());

  return response;
});

export const updateCompanyUser = createAsyncThunk(api.updateCompanyUser, async (payload, { getState, dispatch }) => {
  const state = getState();
  const token = state.auth.user.token;
  const response = await companyUsersAPI.updateCompanyUser(payload, token, true, true);
  await dispatch(getCompanyUsers());

  return await response;
});

export const updateCompanyUserPassword = createAsyncThunk(
  api.updateCompanyUserPassword,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;
    const response = await companyUsersAPI.updateCompanyUserPassword(payload, token, true, true);
    await dispatch(getCompanyUsers());

    return await response;
  }
);
export const deleteCompanyUser = createAsyncThunk(api.deleteCompanyUser, async (payload, { getState, dispatch }) => {
  const state = getState();
  const token = state.auth.user.token;

  const response = await companyUsersAPI.deleteCompanyUser(payload, token, true, true);

  dispatch(getCompanyUsers());
  return response;
});
