import React from "react";
import { Col, ProgressBar, Row } from "react-bootstrap";

export default function Detail_LoadingBar({ item, isSub }) {
  let elementScore = item.normScore ? Number(item.normScore) : item.score;

  return (
    <Row className="justify-content-center mx-0 mb-3">
      <div>
        <h6 className="detail-section-scope-name-header-wrap-up-old">
          {item.name}
          <span className="detail-main-scope-score-header-wrap-up">{elementScore}</span>
        </h6>
        <div className="detail-scope-container-wrap-up">
          <div
            style={{
              paddingTop: "12px",
            }}
          >
            <ProgressBar
              variant={isSub ? "sub-scope" : "main-scope"}
              now={elementScore}
              label={`${elementScore}%`}
              style={{ height: "20px" }}
            />
          </div>
          <Row>
            <Col xs="5">
              <div className="detail-scale-text-wrap-up" dangerouslySetInnerHTML={{ __html: item.lowSideText }} />
            </Col>
            <Col xs={{ offset: 2, span: 5 }}>
              <div className="detail-scale-text-wrap-up" dangerouslySetInnerHTML={{ __html: item.highSideText }} />
            </Col>
          </Row>
          {item.generalInformationText?.length > 2 && (
            <div
              className="detail-scale-general-info-text-wrap-up"
              dangerouslySetInnerHTML={{ __html: item.generalInformationText }}
            />
          )}
        </div>
      </div>
    </Row>
  );
}
