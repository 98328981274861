import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import TestListRowInfo from "./TestListRowInfo";
import TestListRowActionButtons from "./TestListRowActionButtons";

export default function TestEntityListRow({ test }) {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Row xs="auto">
          <TestListRowInfo test={test} />
          <TestListRowActionButtons test={test} />
        </Row>
      </Card.Body>
    </Card>
  );
}
