import React from "react";
import ReportItemEntityListRow from "../Entities/ReportItemEntityListRow";

export default function TestScaleEntity({ entity, children }) {
  return (
    <div className="report-scheme-report-item-row">
      <ReportItemEntityListRow entity={entity} itemName="Test Boyutu" />
      {children && children}
    </div>
  );
}
