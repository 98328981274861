import { createSlice } from "@reduxjs/toolkit";
import {
  createCompanyUser,
  deleteCompanyUser,
  getCompanyUserByCompanyUserId,
  getCompanyUsers,
  updateCompanyUser,
  updateCompanyUserPassword,
} from "./Thunk";
import { companyUserEnum, GetKeyByValue } from "../../../../Helpers/EnumHelper"; //TODO: getCompanyUserByCompanyUserId needs to be implemented here

//TODO: getCompanyUserByCompanyUserId needs to be implemented here

export const companyUser = createSlice({
  name: "companyUser",
  initialState: {
    id: "",
    name: { value: "", isValid: false, cssClass: "" },
    companyUserTypeEnum: { value: "", isValid: false, cssClass: "" },
    companyId: "",
    password: { value: "", isValid: true, cssClass: "" },
    userId: { value: "", isValid: false, cssClass: "" },
    Email: { value: "", isValid: false, cssClass: "" },
    phoneNumber: { value: "", isValid: false, cssClass: "" },
    changePasswordModalData: { email: "", newPassword: "", isShown: false },
  },
  reducers: {
    UpdateCompanyId(state, action) {
      state.companyId = action.payload;
    },
    HandleCompanyUserStateOnChange(state, action) {
      state[action.payload.variableName].value = action.payload.value;
      state[action.payload.variableName].isValid = action.payload.isValid;
      state[action.payload.variableName].cssClass = action.payload.cssClass;
    },
    ClearCompanyUserState(state, action) {
      state.name = { value: "", isValid: false, cssClass: "" };
      state.companyUserTypeEnum = { value: "", isValid: false, cssClass: "" };
      state.password = { value: "", isValid: true, cssClass: "" };
      state.Email = { value: "", isValid: false, cssClass: "" };
      state.phoneNumber = { value: "", isValid: false, cssClass: "" };
      state.isUpdateOperation = false;
    },

    updateChangePasswordModalData(state, action) {
      state.changePasswordModalData[action.payload.variableName] = action.payload.value;
    },

    FetchSelectedCompanyUserData(state, action) {
      state.isUpdateOperation = true;
      state.id = action.payload.companyUser.id;
      state.name = {
        value: action.payload.companyUser.name,
        isValid: true,
        cssClass: "valid-input-buttongroup",
      };
      state.companyUserTypeEnum = {
        value: GetKeyByValue(action.payload.companyUser.companyUserTypeEnum, companyUserEnum),
        isValid: true,
        cssClass: "valid-input-buttongroup",
      };
      state.Email = {
        value: action.payload.companyUser.email,
        isValid: true,
        cssClass: "valid-input-buttongroup",
      };
      state.phoneNumber = {
        value: action.payload.companyUser.phoneNumber,
        isValid: true,
        cssClass: "valid-input-buttongroup",
      };
      state.password = {
        value: action.payload.companyUser.passwordHash,
        isValid: true,
        cssClass: "valid-input-buttongroup",
      };
      state.userId = action.payload.companyUser.applicationUserId;

      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createCompanyUser.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createCompanyUser.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deleteCompanyUser.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteCompanyUser.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateCompanyUser.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateCompanyUser.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateCompanyUserPassword.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateCompanyUserPassword.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getCompanyUsers.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getCompanyUsers.fulfilled, (state, action) => {
      state.companyUserList = action.payload;
      state.isLoading = false;
    });

    builder.addCase(getCompanyUserByCompanyUserId.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getCompanyUserByCompanyUserId.fulfilled, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const {
  UpdateCompanyId,
  updateChangePasswordModalData,
  HandleCompanyUserStateOnChange,
  ClearCompanyUserState,
  FetchSelectedCompanyUserData,
} = companyUser.actions;

export default companyUser.reducer;
