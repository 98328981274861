export const NormalizeScore = (item) => {
  const score = item.score;
  const ranges = item.scale.ranges;
  const numOfRanges = ranges.length;
  const oneStep = Math.round(100 / numOfRanges);
  let counter = 0;
  let percentage = 0;
  for (let i = 0; i < ranges.length; i++) {
    const range = ranges[i];
    if (score > range.start && score > range.end) counter++;
    else if ((score > range.start && score <= range.end) || (range.start === 0 && score === 0)) {
      let filledArea = score - range.start;
      percentage = Number(filledArea / (range.end - range.start)).toFixed(2);
    } else break;
  }

  item.normScore = Math.round(counter * oneStep + percentage * oneStep).toFixed(0);
  if (item.normScore > 100) item.normScore = 100;
  else if (item.normScore < 0) item.normScore = 0;

  for (let i = 0; i < ranges.length; i++) {
    const range = ranges[i];
    const start = i * oneStep;
    const end = (i + 1) * oneStep;
    if ((item.normScore > start && item.normScore <= end) || (range.start === 0 && item.normScore === 0)) {
      range.isHit = true;
    }
  }
};

export const HandleTestScore = (ranges, score) => {
  const oneStep = Math.round(100 / ranges.length);
  for (let i = 0; i < ranges.length; i++) {
    const range = ranges[i]; //TODO: oneStep kısmını gözden geçir
    const start = i * oneStep;
    const end = (i + 1) * oneStep;
    if ((score > start && score <= end) || (range.start === 0 && score === 0)) {
      range.isHit = true;
    }
  }
};
