import React from "react";
import { Col, Row } from "react-bootstrap";

export default function Summary_1({ item, isSub }) {
  const isHtml = item.scale.isHtml;

  let elementScore = item.normScore ? Number(item.normScore) : item.score;

  return (
    <Row className="mb-3 mx-0">
      <Col xs={3} className={"align-content-center"}>
        <Row>
          <Col className={"align-content-center " + (!isSub ? "main-scope-name-wrap-up" : "sub-scope-name-wrap-up")}>
            {item.name}
          </Col>
          <Col style={{ display: "inline-block", float: "right" }}>
            <div className={!isSub ? "main-scope-score-wrap-up" : "sub-scope-score-wrap-up"}>{elementScore}</div>
          </Col>
        </Row>
      </Col>
      <Col xs={9} className={"align-content-center"}>
        <Row>
          {item.scale.ranges.map((range, i) => {
            let isHit = range.isHit;
            return (
              <Col
                key={i + "-" + range.id + "scopeScale"}
                className={
                  isHit
                    ? isSub
                      ? "scale-style-wrap-up hit-sub-scale-wrap-up"
                      : "scale-style-wrap-up hit-scale-wrap-up"
                    : "scale-style-wrap-up"
                }
              >
                {isHtml ? <div dangerouslySetInnerHTML={{ __html: range.text }} /> : range.text}
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
}
