import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Card from "react-bootstrap/Card";

import Overlay from "../../../../SharedComponents/Overlay";
import CompanyList from "./CompanyList";
import { UpdateCompanyState, UpdateName } from "../Store";
import ScrollableCard from "../../../../SharedComponents/ScrollableCard";
import { createCompany, getAllCompanies, updateCompany } from "../Store/Thunk";

export default function Companies() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.company.isLoading);
  const name = useSelector((state) => state.company.name);
  const companyId = useSelector((state) => state.company.id);
  const isUpdateOperation = useSelector((state) => state.company.isUpdateOperation);

  useEffect(() => {
    dispatch(getAllCompanies());
  }, []);

  const submitForm = (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    if (isUpdateOperation)
      dispatch(
        updateCompany({
          name: formData.get("name"),
          id: companyId,
        })
      );
    else
      dispatch(
        createCompany({
          name: formData.get("name"),
        })
      );

    ClearInputs();

    event.currentTarget.reset();
  };

  const ClearInputs = () => {
    dispatch(
      UpdateCompanyState({
        name: "",
        id: "",
      })
    );
  };

  return (
    <div className={"sadmin-page-container"}>
      {isLoading && <Overlay />}
      <Card body className="mb-3">
        <Form onSubmit={submitForm}>
          <Row>
            <Col xs={12} md={10}>
              <h6>{isUpdateOperation ? companyId : t("companyName")}</h6>
              <InputGroup className="mb-3">
                <Form.Control
                  required
                  name="name"
                  placeholder={t("enterCompanyName")}
                  value={name}
                  onChange={(e) => dispatch(UpdateName(e.target.value))}
                />
              </InputGroup>
            </Col>
            <Col xs={12} md={2} style={{ marginBottom: "0px !important" }}>
              <Row style={{ height: !isUpdateOperation && "100%", margin: 0 }}>
                <Button
                  className="mb-1"
                  type="submit"
                  style={{ width: "100%", height: "100%" }}
                  variant="primary"
                  disabled={!name}
                >
                  {isUpdateOperation ? t("update") : t("create")}
                </Button>
              </Row>
              {isUpdateOperation && (
                <Row style={{ margin: 0 }}>
                  <Button style={{ width: "100%", height: "100%" }} variant="secondary" onClick={ClearInputs}>
                    {t("decline")}
                  </Button>
                </Row>
              )}
            </Col>
          </Row>
        </Form>
      </Card>
      <h5>{t("currentCompanies")}</h5>
      <ScrollableCard>
        <CompanyList />
      </ScrollableCard>
    </div>
  );
}
