import React from "react";
import PdfParameterProgressBar from "./PdfParameterProgressBar";

export default function PdfConsistencyAnalysis({ score }) {
  return (
    <div className="mb-6">
      <h3> Tutarlılık Analizi</h3>
      <PdfParameterProgressBar name="Tutarlılık Skoru" score={score} />
    </div>
  );
}
