export const api = {
  createCompanyUser: "companyUser/CreateCompanyUser",
  updateCompanyUser: "companyUser/UpdateCompanyUser",
  updateCompanyUserPassword: "companyUser/UpdateCompanyUserPassword",
  deleteCompanyUser: "companyUser/DeleteCompanyUser",
  getCompanyUserByCompanyUserId: "companyUser/GetCompanyUserByCompanyUserId",
  getCompanyUsers: "companyUser/GetCompanyUsers",
};

export default class CompanyUsersAPI {
  constructor(httpsClient, cache) {
    this.httpsClient = httpsClient;
    this.cache = cache;
  }

  async createCompanyUser(data, token, eAlert, sAlert) {
    return this.post(api.createCompanyUser, data, token, eAlert, sAlert);
  }

  async deleteCompanyUser(data, token, eAlert, sAlert) {
    return this.get(api.deleteCompanyUser, data, token, eAlert, sAlert);
  }

  async updateCompanyUser(data, token, eAlert, sAlert) {
    return this.post(api.updateCompanyUser, data, token, eAlert, sAlert);
  }

  async updateCompanyUserPassword(data, token, eAlert, sAlert) {
    return this.post(api.updateCompanyUserPassword, data, token, eAlert, sAlert);
  }

  async getCompanyUsers(data, token, eAlert, sAlert) {
    return this.get(api.getCompanyUsers, data, token, eAlert, sAlert);
  }

  async getCompanyUserByCompanyUserId(data, token, eAlert, sAlert) {
    return this.get(api.getCompanyUserByCompanyUserId, data, token, eAlert, sAlert);
  }

  async get(url, data, token, eAlert, sAlert) {
    const response = await this.httpsClient.get(url, data, token, eAlert, sAlert);
    return response;
  }

  async post(url, data, token, eAlert, sAlert) {
    const response = await this.httpsClient.post(url, data, token, eAlert, sAlert);

    return response;
  }
}
