import React from "react";
import { useNavigate } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import IconSvg from "../../App/AppMenu/IconSvg";
import { Button, Card } from "react-bootstrap";

export default function BreadCrumb({ children, pageName, backPageName, backUrl, backState }) {
  const navigate = useNavigate();

  return (
    <Row className="mb-3">
      <Col xs="12" md="3">
        <Card body className={"h-100"}>
          <Row xs="auto" className={"h-100 align-content-center"}>
            <Col onClick={() => navigate(backUrl, backState)}>
              <Button size="sm" variant="light">
                <IconSvg icon="back" /> {backPageName}
              </Button>
            </Col>
            <Col className="breadcrumb-page-name">{pageName}</Col>
          </Row>
        </Card>
      </Col>
      <Col xs="12" md="9" className={"align-content-center justify-content-center"}>
        <Card className={"h-100"} body>
          {children}
        </Card>
      </Col>
    </Row>
  );
}
