import React from "react";
import { ResultRow } from "./ResultRow";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ScrollableCard from "../../../../SharedComponents/ScrollableCard";

export function ResultTable(props) {
  const { t } = useTranslation();
  return (
    <div>
      <br />
      <Row className="ticket-result-table-row-container-header">
        <Col xs="12" md="6">
          <Row>
            <Col xs="1" md="2">
              ID
            </Col>
            <Col xs="11" md="4">
              {t("candidateName")}
            </Col>
            <Col xs="12" md="6">
              {t("email")}
            </Col>
          </Row>
        </Col>
        <Col xs="12" md="5">
          <Row>
            <Col xs="6" md="3">
              Uygulama
            </Col>
            <Col xs="6" md="3">
              {t("sent")}
            </Col>
            <Col xs="6" md="3">
              {t("end")}
            </Col>
            <Col xs="6" md="3">
              {t("start")}
            </Col>
          </Row>
        </Col>
        <Col xs="12" md="1"></Col>
      </Row>
      <ScrollableCard>
        {props.tickets?.map((ticket, i) => (
          <ResultRow key={i} ticket={ticket} />
        ))}
      </ScrollableCard>
    </div>
  );
}
