import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CreateQR from "../../../../../../SharedComponents/CreateQR";

export default function OpenInventoryButton({ application }) {
  const { t } = useTranslation();
  const demoUrl = window.location.origin + "/#/iapp/" + application.id;
  const openPage = () => {
    window.open(demoUrl, "_blank");
  };

  return (
    <Row>
      <Col>
        <Button disabled={!application?.questionsImage} size="sm" variant="primary" onClick={openPage}>
          {t("Demo")}
        </Button>
      </Col>
      <Col>
        <CreateQR size={64} value={demoUrl} />
      </Col>
    </Row>
  );
}
