import React from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import BreadCrumb from "../../../../../Components/Dashboard/BreadCrumb";
import CreateScope from "../CreateScope/CreateScope";
import SubScopeList from "./SubScopeList";
import SubScopeModal from "./SubScopeModal";

import { createSubScope } from "../../Store/Thunk";
import { useTranslation } from "react-i18next";

export default function SubScope() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const CreateSubScopeEntity = (subScope) => {
    dispatch(createSubScope({ subScope, scopeId: state.scope.id }));
  };

  return (
    <div className="sadmin-page-container">
      <BreadCrumb
        pageName={t("addSubScope")}
        backPageName={t("scopes")}
        backUrl="/scope"
        backState={{ state: state.application }}
      >
        <Row>
          <Col>
            <h4>
              {state.application.isTest ? t("application") : t("inventory")} {t("firstName")} : {state.application.name}
            </h4>
          </Col>
          <Col>
            <h4>
              {t("ScopeNameInfoLabel")} : {state.scope.name}
            </h4>
          </Col>
        </Row>
      </BreadCrumb>

      <CreateScope
        CreateScope={CreateSubScopeEntity}
        application={state.application}
        scopeId={state.scope.id}
        uiString={"Alt Boyut"}
      />

      <h5>{t("SubScopesListHeaderLabel")}</h5>

      <SubScopeList />
      <SubScopeModal />
    </div>
  );
}
