import React from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import validator from "validator";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateChangePasswordModalData } from "../Store";
import { updateCompanyUserPassword } from "../Store/Thunk";

const ChangePasswordModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const changePasswordModalData = useSelector((state) => state.companyUser.changePasswordModalData);

  const HandleSubmitUpdateButton = (event) => {
    event.preventDefault();

    dispatch(
      updateCompanyUserPassword({
        Email: changePasswordModalData.email,
        newPassword: changePasswordModalData.newPassword,
      })
    );

    HandleModalOnHide();
  };

  const HandleModalOnHide = () => {
    dispatch(
      updateChangePasswordModalData({
        variableName: "isShown",
        value: false,
      })
    );
    dispatch(
      updateChangePasswordModalData({
        variableName: "email",
        value: "",
      })
    );
    dispatch(
      updateChangePasswordModalData({
        variableName: "newPassword",
        value: "",
      })
    );
  };
  return (
    <Modal className={"modal-lg"} show={changePasswordModalData.isShown} centered onHide={HandleModalOnHide}>
      <Modal.Body>
        <Form onSubmit={HandleSubmitUpdateButton}>
          <Row className={"justify-content-between"}>
            <Col xs="auto" className={"align-content-center"}>
              {changePasswordModalData.email}
            </Col>
            <Col>
              <Form.Control
                name="password"
                placeholder={t("password")}
                value={changePasswordModalData.newPassword}
                className={
                  validator.isStrongPassword(changePasswordModalData.newPassword)
                    ? "valid-input-buttongroup "
                    : "invalid-input-buttongroup "
                }
                onChange={(e) => {
                  dispatch(
                    updateChangePasswordModalData({
                      variableName: "newPassword",
                      value: e.target.value,
                    })
                  );
                }}
              />
            </Col>
            <Col xs="auto">
              <Button
                type={"submit"}
                variant="primary"
                disabled={
                  !validator.isStrongPassword(changePasswordModalData.newPassword) &&
                  changePasswordModalData.newPassword.length !== 0
                }
              >
                {t("updatePasswordButtonLabel")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
export default ChangePasswordModal;
