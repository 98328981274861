import React from "react";
import AppMenu from "./AppMenu";
import UseAuthHelper from "../../Helpers/Auth/UseAuthHelper";
import AppHeader from "../AppHeader";

export default function AppLayout({ children }) {
  const { auth } = UseAuthHelper();
  const href = window.location.href;
  return (
    <div>
      {!auth.data ||
      href.includes("app") ||
      href.includes("application") ||
      href.includes("demography") ||
      href.includes("demoResult") ||
      href.includes("wrapup") ? (
        <>
          <AppHeader />
          {children}
        </>
      ) : (
        <>
          <AppMenu role={auth?.data?.userRole[0]} userName={auth?.data?.userName} />
          <div className="page-container">{children}</div>
        </>
      )}
    </div>
  );
}
