import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Button } from "react-bootstrap";
import CreateQR from "../../../../../SharedComponents/CreateQR";

export default function OpenTestDemo({ test }) {
  const { t } = useTranslation();
  const demoUrl = window.location.origin + "/#/tapp/" + test.id;

  const openPage = () => {
    window.open(demoUrl, "_blank");
  };

  return (
    <Row>
      <Col>
        <Button disabled={!test.questionsImage} size="sm" variant="primary" onClick={openPage}>
          {t("Demo")}
        </Button>
      </Col>
      <Col>
        <CreateQR size={64} value={demoUrl} />
      </Col>
    </Row>
  );
}
