import React, { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { ComponentToPrint } from "./ComponentToPrint";

import { HandleGroupingResultImageForCalculation } from "../../../Helpers/ResultImagesGroupingHelper";
import ApiHelper from "../../../Helpers/ApiHelper";
import Overlay from "../../../SharedComponents/Overlay";
import WholePdfFile from "./PdfFile/WholePdfFile";
import DownloadButtonUi from "./DownloadButtonUi";
import "../Styles/PdfFile.css";

export default function TestPdfButton({ demoResultId, ticketResultId }) {
  const [showOverlay, setShowOverlay] = useState(false);
  const [pdfComponent, setPdfComponent] = useState(null);
  const componentRef = useRef(null);

  const CreateQrUrl = () => {
    let qrURL = "";
    if (demoResultId) qrURL = window.location.origin + "/#/tdemoResult/" + demoResultId;
    else if (ticketResultId) qrURL = window.location.origin + "/#/tResult/" + ticketResultId;
    return qrURL;
  };

  const HandlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {
      setShowOverlay(false);
    },
    onAfterPrint: () => {
      setPdfComponent(null);
    },
  });

  let HandleEachPage = (pageReportItems, calculation) => {
    pageReportItems.forEach((reportItem) => {
      if (reportItem.htmlEl) return;
      else if (reportItem.componentName === "TestScaleEntity") reportItem.score = calculation.testScore;
      else reportItem.score = calculation?.calculatedScopes.find((x) => x.scopeId === reportItem.scopeId)?.score;
    });
  };

  let MapTestReportSchemeWithCalculation = (data, demography) => {
    let appData = data.appData;
    let scheme = appData.adminPdfJson && JSON.parse(appData.adminPdfJson);

    scheme.forEach((page) => HandleEachPage(page, data?.calculation));
    setPdfComponent(
      <WholePdfFile
        StartDownloadPdf={HandlePrint}
        scheme={scheme}
        appData={appData}
        demography={demography}
        type="test"
        qrURL={CreateQrUrl()}
      />
    );
  };

  let CalculateTest = (result) => {
    let demography = {
      name: result.name,
      email: result.email,
      department: result.department,
      faculty: result.faculty,
      school: result.school,
      birthYear: result.birthYear,
      gender: result.gender,
    };

    const postObj = HandleGroupingResultImageForCalculation(result);
    ApiHelper.postAsync(
      "user/CalculateTest",
      postObj,
      function (response) {
        MapTestReportSchemeWithCalculation(response.data.data, demography);
      },
      null
    );
  };

  let StartPdfExportProcess = async () => {
    setShowOverlay(true);

    if (demoResultId) {
      ApiHelper.getAsync(
        "user/GetDemoResult",
        { demoResultId },
        function (response) {
          CalculateTest(response.data.data);
        },
        null
      );
    } else if (ticketResultId) {
      //ticketResult getir sonra role gore pdf scheme getir
    }
  };

  return (
    <>
      {showOverlay && <Overlay text="Pdf Dosyası Hazırlanıyor" />}
      <DownloadButtonUi StartPdfExportProcess={StartPdfExportProcess} />
      <ComponentToPrint ref={componentRef}>
        <div className="onlyPrint" ref={componentRef}>
          {pdfComponent}
        </div>
      </ComponentToPrint>
    </>
  );
}
