import React from "react";
import { Image, Card, Col, Row } from "react-bootstrap";
import ApiHelper from "../../../../../Helpers/ApiHelper";
import { useTranslation } from "react-i18next";

export function Applications(props) {
  const { t } = useTranslation();
  return (
    <>
      <h3 className="admin-dash-headings" style={{ padding: "20px 0px 0px 30px" }}>
        {t("Current credits")}
      </h3>
      <Row className="applications-container-row" id="apps">
        {props.credits?.map((credit, i) => (
          <Col xs="auto" key={i}>
            <Card className="admin-dash-app-card" style={{ background: credit?.test?.isTest && "green" }}>
              <Card.Body>
                <div className="flex-center">
                  <Image
                    height={100}
                    width={100}
                    src={ApiHelper.baseURL + "wwwroot/info/" + credit?.test?.iconImageName}
                    roundedCircle
                  />
                </div>
                <h5 style={{ textAlign: "center" }}>{credit?.test?.name}</h5>
                <h4 className="credit-amount-container" style={{ background: credit?.test?.isTest && "#006804" }}>
                  {credit?.creditAmount}
                </h4>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
}
