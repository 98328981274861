import React, { useState } from "react";
import { Col, Modal, Row, Button } from "react-bootstrap";
import IconSvg from "../../../../App/AppMenu/IconSvg";
//todo new Pdf download button need to be imported
// import { PdfDownloadButton } from "../../../Application/ApplicationResults//Pdf/PdfDownloadButton";
import ApiHelper from "../../../../Helpers/ApiHelper";

export function ResultRow({ ticket }) {
  if (ticket?.ticketResult) ticket.ticketResult.testId = ticket.testId;

  const [modalData, setModalData] = useState({
    show: false,
    data: null,
  });

  let HandleDateString = (dateData) => {
    if (!dateData) return "--";
    const date = new Date(dateData);
    return `${date.getDate().toString().padStart(2, "0")}.${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}.${date.getFullYear().toString().substr(-2)}`;
  };

  let GetTime = (dateData) => {
    if (!dateData) return "--";
    const date = new Date(dateData);
    const hours = date.getHours();
    const hoursPrefix = hours < 10 ? "0" : null;
    const minutes = date.getMinutes();
    const minutesPrefix = minutes < 10 ? "0" : null;
    return `${hoursPrefix + hours}:${minutesPrefix + minutes}`;
  };

  let HandleOpenResultInNewTab = () => {
    if (!ticket?.ticketResult?.isFinished) return;

    window.open(window.location.origin + "/#/wrapup/" + ticket?.ticketResult?.id, "_blank");
  };

  let OpenPhotosModal = (data) => {
    setModalData({ show: true, data });
  };

  let HandlePhotos = (photos) => {
    const images = JSON.parse(photos);
    if (images)
      return images.map((imageName, i) => (
        <img className="candidate-photo" key={i + "photo"} src={ApiHelper.baseURL + "wwwroot/candidate/" + imageName} />
      ));
  };
  return (
    <>
      <Row
        style={{
          background: !ticket?.ticketResult?.isFinished && "#fff0f5",
          paddingTop: "13px",
          margin: "10px",
          borderRadius: "10px",
        }}
        // some problems occurs in downloaded pdf when you make this style come from a css class
      >
        <Col xs="12" md="6">
          <Row>
            <Col xs="1" md="2">
              <h6
                style={{
                  fontWeight: 700,
                  color: ticket?.ticketResult?.isFinished ? "green" : "red",
                }}
              >
                {ticket.id}
              </h6>
            </Col>
            <Col xs="11" md="4">
              <h6>{ticket.candidateUser?.demography?.name}</h6>
            </Col>
            <Col xs="12" md="6">
              <h6 onClick={() => navigator.clipboard.writeText(ticket.candidateUser.demography?.email)}>
                {ticket.candidateUser?.email} <IconSvg icon="copy-text" />
              </h6>
            </Col>
          </Row>
        </Col>
        <Col xs="12" md="5">
          <Row>
            <Col xs="6" md="3">
              <h6>{ticket.test.name}</h6>
            </Col>
            <Col xs="6" md="3">
              <h6>
                {HandleDateString(ticket.createdAt)}
                <span className="time-stamp">{GetTime(ticket.createdAt)}</span>
              </h6>
            </Col>
            <Col xs="6" md="3">
              <h6>
                {HandleDateString(ticket.ticketResult?.startDate)}
                <span className="time-stamp">{GetTime(ticket.ticketResult?.startDate)}</span>
              </h6>
            </Col>
            <Col xs="6" md="3">
              <Row>
                <Col xs="8">
                  <h6>
                    {HandleDateString(ticket.ticketResult?.endDate)}
                    <span className="time-stamp">{GetTime(ticket.ticketResult?.endDate)}</span>
                  </h6>
                </Col>
                <Col xs="4">
                  {ticket?.ticketResult?.jsonJpgNames && (
                    <span
                      className="ticket-result-photos-container"
                      onClick={() => OpenPhotosModal(ticket?.ticketResult?.jsonJpgNames)}
                    >
                      <IconSvg icon="photos" />
                    </span>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs="12" md="1">
          <Row>
            <Col xs="6" md="9" style={{ marginTop: "-10px" }}>
              {/* <PdfDownloadButton
                isActive={ticket?.ticketResult?.isFinished}
                result={ticket?.ticketResult}
                resultPage={false}
                demography={ticket?.candidateUser?.demography}
              /> */}
            </Col>
            <Col xs="6" md="3" className="open-result-new-tab-icon-col" onClick={HandleOpenResultInNewTab}>
              {ticket?.ticketResult?.isFinished && <IconSvg icon="new-tab" />}
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal size="lg" centered show={modalData.show} onHide={() => setModalData({ show: false, data: null })}>
        <Modal.Header closeButton>
          <Modal.Title>Adayın Fotoğrafları</Modal.Title>
        </Modal.Header>
        <Modal.Body>{HandlePhotos(modalData.data)}</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalData({ show: false, data: null })}>Kapat</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
