import React from "react";
import { ResultRow } from "./ResultRow";
import { Col, Row } from "react-bootstrap";
import ScrollableCard from "../../../../SharedComponents/ScrollableCard";
import { useTranslation } from "react-i18next";

export function ResultTable(props) {
  const { t } = useTranslation();

  return (
    <div>
      <br />
      <Row className="ticket-result-table-row-container-header">
        <Col xs="1" md="1">
          ID
        </Col>
        <Col xs="6" md="3">
          Uygulama
        </Col>
        <Col xs="6" md="2">
          Tanımlanma
        </Col>
        <Col xs="6" md="2">
          Başlama
        </Col>
        <Col xs="6" md="2">
          Bitirme
        </Col>
        <Col xs="1" md="2"></Col>
      </Row>
      <ScrollableCard>
        {props.tickets?.map((ticket, i) => (
          <ResultRow key={i} ticket={ticket} />
        ))}
      </ScrollableCard>
    </div>
  );
}
