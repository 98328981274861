import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";

import ApiHelper from "../../../Helpers/ApiHelper";
import { HandleGroupingResultImageForCalculation } from "../../../Helpers/ResultImagesGroupingHelper";
import Overlay from "../../../SharedComponents/Overlay";
import WrapUpHeader from "./WrapUpHeader";
import ReportItemMapper from "./ReportItemMapper";
import InventoryPdfButton from "../../PdfDownloadButton/Components/InventoryPdfButton";

import "../Styles/index.css";
import "../Styles/WrapUp.css";
import "animate.css";

export default function InventoryWrapUp() {
  const { state } = useLocation();
  const { ticketResultId } = useParams();
  const { demoResultId } = useParams();
  const [demography, setDemography] = useState();
  const [appData, setAppData] = useState();
  const [_scheme_, setScheme] = useState([]);

  const [showOverlay, setShowOverlay] = useState(true);

  useEffect(() => {
    if (state) {
      CalculateInventory(state);
    } else if (demoResultId) {
      ApiHelper.getAsync(
        "user/GetDemoResult",
        { demoResultId },
        function (response) {
          if (response.data.statusCode) CalculateInventory(response.data.data);
          else alert("bir hata oluştu"); //todo burada hatayi handle et , guzek bir alert kutusu goster gerekirse sayfayi kapat
        },
        null
      );
    } else if (ticketResultId) {
      //ticketResult getir sonra role gore pdf scheme getir
    } else {
      setShowOverlay(false);
      alert("problem");
    }
  }, []);

  const CreateQrUrl = () => {
    let qrURL = "";
    if (demoResultId) qrURL = window.location.origin + "/#/idemoResult/" + demoResultId;
    else if (ticketResultId) qrURL = window.location.origin + "/#/iResult/" + ticketResultId;
    return qrURL;
  };

  let CalculateInventory = (result) => {
    setDemography({
      name: result.name,
      email: result.email,
      department: result.department,
      faculty: result.faculty,
      school: result.school,
      birthYear: result.birthYear,
      gender: result.gender,
    });

    const postObj = HandleGroupingResultImageForCalculation(result, false);
    ApiHelper.postAsync(
      "user/CalculateInventory",
      postObj,
      function (response) {
        MapInventoryReportSchemeWithCalculation(response.data.data);
      },
      null
    );
  };

  let MapInventoryReportSchemeWithCalculation = (data) => {
    let appData = data.appData;
    setAppData(appData);
    let calculatedScopes = data?.calculation?.calculatedScopes;
    let scheme = appData.adminWrapUpJson && JSON.parse(appData.adminWrapUpJson);
    let consistencyScore = data.calculation.consistencyScore.score;
    let inventoryLeanNumbers = data.calculation.inventoryLeanNumbers;

    scheme.forEach((reportItem) => {
      if (reportItem.htmlEl) return;
      else if (reportItem.componentName === "AnsweringTendencyEntity") {
        reportItem.consistencyScore = consistencyScore;
        reportItem.inventoryLeanNumbers = inventoryLeanNumbers;
      } else if (reportItem.subScopeId)
        reportItem.score = calculatedScopes.find(
          (x) => x.isSub && x.scopeId === reportItem.subScopeId
        )?.calculatedScore;
      else {
        reportItem.score = calculatedScopes.find(
          (x) => x.isSub === false && x.scopeId === reportItem.scopeId
        )?.calculatedScore;
      }
    });

    setScheme(scheme);
    setShowOverlay(false);
  };

  return (
    <Container>
      {showOverlay && <Overlay text="Sonuçlar hazırlanıyor" />}
      <WrapUpHeader userDemography={demography} applicationName={appData?.name} CreateQrUrl={CreateQrUrl}>
        <InventoryPdfButton demoResultId={demoResultId} ticketResultId={ticketResultId} />
      </WrapUpHeader>
      <ReportItemMapper calculatedScheme={_scheme_} type={"inventory"} />
    </Container>
  );
}
