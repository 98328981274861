import React, { useState, useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import Swal from "sweetalert2";

import withReactContent from "sweetalert2-react-content";

import UseAuthHelper from "../../../Helpers/Auth/UseAuthHelper";
import ApiHelper from "../../../Helpers/ApiHelper";
import ScrollableCard from "../../../SharedComponents/ScrollableCard";
import Overlay from "../../../SharedComponents/Overlay";
import BreadCrumb from "../../Dashboard/BreadCrumb";

const MySwal = withReactContent(Swal);

export function ReorderQuestions() {
  const { auth } = UseAuthHelper();
  const user = auth?.data;

  const { state } = useLocation();
  const navigate = useNavigate();
  const isTest = state.applicationType == "Inventory" ? false : true;
  const [questions, setQuestions] = useState([]);
  const [likertAnswers, setLikertAnswers] = useState(null);
  const [checkboxes, setCheckboxes] = useState([]);
  const [showOverlay, setShowOverlay] = useState(false);
  const [newOrder, setNewOrder] = useState("");
  const [questionsImage, setQuestionsImage] = useState(null);

  useEffect(() => {
    if (questions.length === 0) {
      GetQuestions();
    }
  }, []);

  const GetQuestions = () => {
    setShowOverlay(true);
    ApiHelper.getAsync(
      "question/GetAllQuestions",
      {
        testId: isTest ? state.id : 0,
        inventoryId: !isTest ? state.id : 0,
      },
      function (result) {
        if (result.data.statusCode) HandleQuestionObjectsForUi(result.data?.data);
        else alert(result.data.data);
      },
      user.token
    );
  };

  const HandleQuestionObjectsForUi = (application) => {
    if (application.questionsImage) setQuestionsImage(application.questionsImage);
    let scopes = application.scopes;
    if (application.answers) setLikertAnswers(application.answers.options.map((x) => x.text));
    let checkboxesArr = [];
    let questionArr = [];
    scopes.forEach((scope) => {
      scope?.questions?.forEach((sq) => {
        sq.scope = scope.name;
        sq.scopeId = scope.id;
        sq.isConsistency = scope.isConsistency;
        sq.isManipulation = scope.isManipulation;
        if (sq.checkbox) checkboxesArr.push(sq);
        else questionArr.push(sq);
      });
      scope?.subScopes?.forEach((subScope) => {
        subScope.questions.forEach((ssq) => {
          ssq.subScope = subScope.name;
          ssq.subScopeId = subScope.id;
          ssq.scope = scope.name;
          ssq.isManipulation = subScope.isManipulation;
          ssq.isConsistency = subScope.isConsistency;
          if (ssq.checkbox) checkboxesArr.push(ssq);
          else questionArr.push(ssq);
        });
      });
    });
    let unOrderedOnes = questionArr.filter((x) => {
      return x.order === 0;
    });
    let orderedOnes = questionArr.filter((x) => {
      return x.order !== 0;
    });
    orderedOnes.sort(function (a, b) {
      return a.order - b.order;
    });
    const lastOrder = orderedOnes.length !== 0 ? orderedOnes[orderedOnes.length - 1].order : 0;

    for (let i = 0; i < unOrderedOnes.length; i++) {
      unOrderedOnes[i].order = lastOrder + i + 1;
      unOrderedOnes[i].unOrdered = true;
    }
    questionArr.sort(function (a, b) {
      return a.order - b.order;
    });

    setQuestions(questionArr);

    if (checkboxesArr.length > 0) setCheckboxes(checkboxesArr);
    setShowOverlay(false);
  };

  const FilterQuestionsForJsonImage = (questionsArr) => {
    let filteredImage = [];
    questionsArr.forEach((x) => {
      if (state.isTest) {
        filteredImage.push({
          questionId: x.id,
          testItemId: x.testItem.id,
          order: x.order,
          text: x.testItem.questionText,
          imagePath: x.testItem.imagePath,
          scopeId: x.scopeId,
          options: x.testItem.answers.options.map((opt) => ({
            id: opt.id,
            option: opt.text,
            path: opt.path,
          })),
        });
      } else if (x.likert) {
        filteredImage.push({
          questionId: x.id,
          likertId: x.likert.id,
          order: x.order,
          text: x.likert.questionText,
          scopeId: x.scopeId,
          subScopeId: x.subScopeId,
        });
      } else if (x.polared) {
        filteredImage.push({
          questionId: x.id,
          polaredId: x.polared.id,
          order: x.order,
          leftPolar: x.polared.leftPolar,
          rightPolar: x.polared.rightPolar,
          scopeId: x.scopeId,
          subScopeId: x.subScopeId,
        });
      }
    });
    return filteredImage;
  };

  const DeleteOrders = () => {
    if (!questionsImage.id) return alert("bir hata oluştu");
    setShowOverlay(true);
    var ids = questions.map((q) => q.id);

    ApiHelper.postAsync(
      "question/DeleteQuestionsImage",
      {
        QuestionIds: ids,
        id: questionsImage.id,
      },
      function (result) {
        setShowOverlay(false);
        if (result.data.statusCode === 1) {
          GetQuestions();
          MySwal.fire(<p>{state.isTest ? "Test" : "Envanter"} Sıralama silindi</p>);
        } else MySwal.fire(<p>Bir hata olustu </p>);
      },
      user.token
    );
  };

  const CreatePostObjThenSaveToDb = () => {
    setShowOverlay(true);
    let ordersAndIds = [];

    questions.forEach((q) => {
      if (q.unOrdered) {
        ordersAndIds.push({ Id: q.id, Order: q.order });
      }
    });

    PostQuestionsImageJson({
      testId: isTest ? state.id : 0,
      inventoryId: !isTest ? state.id : 0,
      ordersAndIds,
      QuestionsJson: JSON.stringify(FilterQuestionsForJsonImage(questions)),
      AnswersJson: likertAnswers && JSON.stringify(likertAnswers),
      QuestionCount: questions.length,
    });
  };

  const PostQuestionsImageJson = (postObj) => {
    ApiHelper.postAsync(
      "question/UpsertQuestionsImage",
      postObj,
      function (result) {
        setShowOverlay(false);
        if (result.data.statusCode === 1) {
          GetQuestions();
          MySwal.fire(<p>{state.isTest ? "Test" : "Envanter"} imajı kaydedildi</p>);
        } else MySwal.fire(<p>Bir hata olustu </p>);
      },
      user.token
    );
  };

  const ChangeOrder = (prevOrder) => {
    var _newOrder = parseInt(newOrder);
    if (_newOrder > questions.length || _newOrder === prevOrder || _newOrder <= 0) return;

    let newList = JSON.parse(JSON.stringify(questions));
    let entity = newList.find((q) => q.order === prevOrder);
    newList = newList.filter((x) => x.order != prevOrder);
    newList.forEach((x, i) => (x.order = i + 1));

    let firstHalf = [];
    let secondHalf = [];
    newList = JSON.parse(JSON.stringify(newList));
    newList.forEach((x, i) => {
      if (i + 1 < _newOrder) firstHalf.push(x);
      else {
        x.unOrdered = true;
        secondHalf.push(x);
      }
    });

    entity.unOrdered = true;
    firstHalf.push(entity);

    firstHalf = firstHalf.concat(secondHalf);
    let resultList = JSON.parse(JSON.stringify(firstHalf));
    resultList.forEach((x, i) => {
      x.order = i + 1;
    });
    setNewOrder("");
    setQuestions(resultList);
  };

  const HandleFileQuestion = (fileName) => {
    if (fileName.endsWith(".mp3"))
      return (
        <audio controls name="media">
          <source src={ApiHelper.baseURL + "wwwroot/question/" + fileName} type="audio/mpeg" />
        </audio>
      );
    else if (fileName.endsWith(".webm"))
      return (
        <video controls name="media" width="130" height="130">
          <source src={ApiHelper.baseURL + "wwwroot/question/" + fileName} type="video/webm" />
        </video>
      );
    else if (fileName.endsWith(".mp4"))
      return (
        <video controls name="media" width="130" height="130">
          <source src={ApiHelper.baseURL + "wwwroot/question/" + fileName} type="video/mp4" />
        </video>
      );
    return <Image src={ApiHelper.baseURL + "wwwroot/question/" + fileName} width="130" height="130" />;
  };

  const HandleLikert = (data) => {
    return <Col>{data.questionText && <span style={{ fontWeight: "bold" }}> {data.questionText} </span>}</Col>;
  };

  const HandlePolared = (data) => {
    return (
      <React.Fragment>
        <Col>
          Sol Kutup:
          <span style={{ fontWeight: "bold" }}> {data.leftPolar}</span> Sağ Kutup:
          <span style={{ fontWeight: "bold" }}>{data.rightPolar}</span>
        </Col>
      </React.Fragment>
    );
  };

  const HandleTestItem = (data) => {
    return (
      <>
        <Col>{data.questionText ? HandleLikert(data) : HandleFileQuestion(data.imagePath)}</Col>
        <Col>K={data.factor}</Col>
      </>
    );
  };

  const HandleQuesionByType = (question) => {
    if (question.polared) return HandlePolared(question.polared);
    else if (question.likert) return HandleLikert(question.likert);
    else if (question.testItem) return HandleTestItem(question.testItem);
  };

  const HandleQuestion = (question, i) => {
    return (
      <Card key={i}>
        <Card.Body style={{ padding: "5px 15px" }}>
          <Row xs="auto" className="mb-2">
            <Col>Id: Alt{question.id} </Col>
            <Col>{question.scope} </Col>
            {question.subScope && <Col>{question.subScope}</Col>}
            {question.isManipulation && (
              <Col>
                <Badge bg="info">Manipülasyon</Badge>
              </Col>
            )}
            {question.isConsistency && (
              <Col>
                <Badge bg="warning">Tutarlılık</Badge>
              </Col>
            )}
          </Row>
          <Row xs="auto">
            <Col xs={12} md={8}>
              {HandleQuesionByType(question)}
            </Col>
            <Col xs={12} md={2}>
              <h2>
                {question.order} {question.unOrdered && <span style={{ color: "red" }}>**</span>}
              </h2>
            </Col>
            <Col xs={12} md={2}>
              <InputGroup size="sm" className="mb-3">
                <Button variant="primary" size="sm" onClick={() => ChangeOrder(question.order)}>
                  Değiştir
                </Button>
                <Form.Control
                  type="number"
                  min="1"
                  max={questions.length}
                  step="1"
                  placeholder={question.order}
                  onChange={(e) => {
                    setNewOrder(e.target.value);
                  }}
                  onClick={(e) => {
                    e.target.value = "";
                  }}
                />
              </InputGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  };

  return (
    <div className="sadmin-page-container">
      {showOverlay && <Overlay text="Sorular Getiriliyor" />}
      <BreadCrumb
        backUrl={state.isTest ? "/test" : "/inventory"}
        pageName="Soru Sıralaması"
        backPageName={state.isTest ? "Testler" : "Envanterler"}
      >
        <Row>
          <Col xs="12" md="4">
            <h4>
              {state.isTest ? "Test" : "Envanter"} adı : {state.name}
            </h4>
          </Col>
          <Col xs="12" md="2">
            <h4>{questions.length} adet soru</h4>
          </Col>
          <Col xs="12" md="3">
            {checkboxes.length > 0 && (
              <Button
                variant="primary"
                size="sm"
                onClick={() =>
                  navigate("/reordercheckboxes", {
                    state: { inventory: state, checkboxes: checkboxes },
                  })
                }
              >
                {checkboxes.length} soru checkbox sirala
              </Button>
            )}
          </Col>
          <Col xs="3">
            <Row xs="auto" className="justify-content-md-end">
              <Col>
                <Button variant="primary" onClick={CreatePostObjThenSaveToDb}>
                  Kaydet
                </Button>
              </Col>
              {questionsImage && (
                <Col>
                  <Button variant="danger" onClick={DeleteOrders}>
                    Mevut Kaydı Sil
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </BreadCrumb>
      <ScrollableCard>{questions?.map((question, i) => HandleQuestion(question, i))}</ScrollableCard>
    </div>
  );
}
