import React from "react";
import PdfParameterProgressBar from "./PdfParameterProgressBar";

export default function PdfTendencyNumbers({ numbers }) {
  return (
    <div>
      <h3> Yönelme Analizleri</h3>
      <PdfParameterProgressBar name="Uçlara Yönelim" score={numbers?.edgeLean} />
      <PdfParameterProgressBar name="Yüksek Uca Yönelim" score={numbers?.highLean} />
      <PdfParameterProgressBar name="Düşük Uca Yönelim " score={numbers?.lowLean} />
      <PdfParameterProgressBar name="Ortalara Yönelim" score={numbers?.middleLean} />
    </div>
  );
}
