import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Select from "react-select";
import { useDispatch } from "react-redux";

import { CreditTable } from "./CreditTable";
import { upsertCredit } from "../Store/CreditThunk";
import { filteredCredits } from "../Store";
import useCreditsData from "../Hooks/useCreditsData";
import "../Styles/index.css";

export default function LoadCredit() {
  const dispatch = useDispatch();
  const { adminsList, testsList } = useCreditsData();

  const submitForm = (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    dispatch(
      upsertCredit({
        userId: formData.get("adminValue"),
        testId: formData.get("testValue"),
        creditAmount: formData.get("numberValue"),
      })
    );
  };

  let HandleMailSearch = (value) => {
    dispatch(filteredCredits(value));
  };

  return (
    <div className="sadmin-page-container">
      <Card body style={{ background: "#2c3034" }}>
        <Form onSubmit={submitForm}>
          <Row>
            <Col xs={5}>
              <Select required name="adminValue" options={adminsList} placeholder={"adminler"} />
            </Col>
            <Col xs={4}>
              <Select required name="testValue" options={testsList} placeholder={"testler"} />
            </Col>
            <Col xs={1}>
              <Form.Control
                required
                name="numberValue"
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                type="number"
                min="1"
                step="1"
              />
            </Col>
            <Col xs={2}>
              <Button type="submit" variant="primary" style={{ width: "100%" }}>
                Yükle
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <br></br>

      <CreditTable HandleMailSearch={HandleMailSearch} />
    </div>
  );
}
