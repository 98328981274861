import React from "react";
import { Col, Row } from "react-bootstrap";

export default function Detail_3({ item, isSub }) {
  //const isHtml = item.scale.isHtml;
  let elementScore = item.normScore ? Number(item.normScore) : item.score;

  return (
    <Row className={"mb-3 mx-0"} style={{ marginBottom: "6px" }}>
      {!isSub && (
        <Row className={"justify-content-center jakarta-sans"} style={{ fontSize: 22, fontWeight: 800 }}>
          {item.scopeName}
        </Row>
      )}

      <div className={"py-3 rounded-4"} style={{ backgroundColor: isSub ? "white" : "#0048aa" }}>
        <Row>
          <Col className={"align-content-center"}>
            {isSub && <Row className={"detail-scope-name-blurred-wrap-up mb-2 px-3"}>{item.scopeName}</Row>}
            <Row style={{ color: !isSub ? "white" : "" }} className={"main-scope-name-detail-wrap-up-new px-3 pb-2"}>
              {item.name}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className={"align-content-center"} xs={5}>
            <Row className={"_scale-detail-text-new-wrap-up px-3"}>
              <div
                style={{ color: !isSub ? "white" : "" }}
                dangerouslySetInnerHTML={{ __html: item.generalInformationText }}
              ></div>
            </Row>
          </Col>

          <Col xs={7} className={"align-content-center px-4"}>
            <Row
              className={"justify-content-between mt-1 round-30px border border-2 px-2"}
              style={{ borderColor: "#A6D1FF" }}
            >
              {item.scale.ranges.map((range, i) => {
                if (range.isHit === true) {
                  return (
                    <Col xs={"auto"} className={"align-content-center position-relative p-0"}>
                      <Row className={"slightly-above-absolute-child-centered-wrap-up m-0"}>
                        {item.scale.isHtml ? (
                          <div
                            style={{
                              backgroundColor: isSub ? "#007aff" : "#fff5ce",
                              color: isSub ? "white" : "#00428a",
                            }}
                            className={"z-1"}
                            dangerouslySetInnerHTML={{ __html: item.monoSpaceHtml }}
                          />
                        ) : (
                          <div
                            className={"z-1 detail-hit-range-name-above-wrap-up px-2 w-auto"}
                            style={{
                              backgroundColor: isSub ? "#007aff" : "#fff5ce",
                              color: isSub ? "white" : "#00428a",
                            }}
                          >
                            {range.text}
                          </div>
                        )}
                        <div
                          className={"px-0 position-relative justify-content-center"}
                          style={{ height: 9, width: 15 }}
                        >
                          <div
                            className={"z-0 rotated-div-cube position-absolute"}
                            style={{ top: -8, height: 15, width: 15, backgroundColor: isSub ? "#007aff" : "#fff5ce" }}
                          ></div>
                        </div>
                      </Row>
                      <div className={"hit-scale-style-variable-opacity-wrap-up-round p-0 _flex-center"}>
                        %{elementScore}
                      </div>
                    </Col>
                  );
                } else {
                  return (
                    <Col xs={"auto"} className={"align-content-center p-0"}>
                      <div
                        style={{
                          backgroundColor: "white",
                          width: "fit-content",
                          height: "fit-content",
                          borderRadius: "50%",
                          padding: 0,
                        }}
                      >
                        <div
                          className={"scale-style-variable-opacity-wrap-up-round"}
                          style={{
                            opacity: (i + 1) * (1 / item.scale.ranges.length),
                            backgroundColor: isSub ? "#00428a" : "#02a4ff",
                          }}
                        ></div>
                      </div>
                    </Col>
                  );
                }
              })}
            </Row>
          </Col>
        </Row>
      </div>
    </Row>
  );
}
