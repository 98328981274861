import React from "react";

const PdfContent = ({ children }) => {
  return (
    <div className={"justify-content-center"} style={{ height: "1053px" }}>
      {children}
    </div>
  );
};
export default PdfContent;
