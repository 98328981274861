import React, { useEffect, useState } from "react";
import ApiHelper from "../../../../../../Helpers/ApiHelper";
import UseAuthHelper from "../../../../../../Helpers/Auth/UseAuthHelper";
import { Row, Col } from "react-bootstrap";
import Overlay from "../../../../../../SharedComponents/Overlay";
import { OrdersRecordContainer } from "./OrdersRecordContainer";

export default function SentReports(props) {
  const { auth } = UseAuthHelper();
  const user = auth.data;
  const [ordersRecords, setOrdersRecords] = useState([]);
  const [showOverlay, setShowOverlay] = useState(true);

  useEffect(() => {
    GetLastOrdersRecords();
  }, []);

  const HandleSettingOrdersRecord = (data) => {
    for (let i = 0; i < data.length; i++) {
      const ordersRecord = data[i];
      ordersRecord.success = JSON.parse(ordersRecord.jsoN_SuccesRegistrations);
      ordersRecord.fail = JSON.parse(ordersRecord.jsoN_UnSuccesRegistrations);
      ordersRecord.request = JSON.parse(ordersRecord.jsoN_SendApplicationsToCandidatesDto);
      ordersRecord.sentApps = [];
      ordersRecord.request.CreditIds.forEach((id) => {
        const appName = props.creditsAndApps?.find((creditAndApp) => creditAndApp.creditId === id);
        ordersRecord.sentApps.push(appName);
      });
    }
    setOrdersRecords(data);
    setShowOverlay(false);
  };

  const GetLastOrdersRecords = () => {
    ApiHelper.getAsync(
      "credit/GetLastTenOrders",
      { userId: user.userId },
      function (result) {
        if (result.status !== 200) {
        } else if (result.data.statusCode === 1) {
          HandleSettingOrdersRecord(result.data.data);
        } else {
        }
      },
      user.token
    );
  };

  return (
    <div className="project-page-component-container">
      {showOverlay && <Overlay />}
      <Row className="orders-record-container-header">
        <Col xs="12" md="3">
          Aday E-Posta
        </Col>
        <Col xs="12" md="6">
          Uygulamalar
        </Col>
        <Col xs="12" md="3" style={{ display: "flex", justifyContent: "flex-end" }}>
          Gönderim Zamanı
        </Col>
      </Row>
      <div className="ticket-result-table-row-container-body" style={{ height: window.innerHeight - 270 }}>
        {ordersRecords?.map((ordersRecord, i) => (
          <OrdersRecordContainer key={i + "or"} ordersRecord={ordersRecord} i={i} />
        ))}
      </div>
    </div>
  );
}
