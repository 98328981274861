export const HandleGroupingResultImageForCalculation = (result, isTest) => {
  let questionAnswerJson = result?.questionAnswersJson?.length ? result.questionAnswersJson : "[]";
  let checkboxAnswersJson = result?.checkboxAnswersJson?.length ? result.checkboxAnswersJson : "[]";
  let groupedScopes = HandleGroupingScopes(JSON.parse(questionAnswerJson), JSON.parse(checkboxAnswersJson));

  return {
    isTest,
    scopes: HandleGroupingQuestions(groupedScopes.scope),
    subScopes: HandleGroupingQuestions(groupedScopes.subScope, true),
    result: result,
  };
};

let HandleGroupingScopes = (polarLikertAns, adjAns) => {
  let scope = {};
  let subScope = {};
  let concatedArr = [...polarLikertAns, ...adjAns];

  concatedArr.forEach((item) => {
    if (item.scopeId) {
      if (scope[item.scopeId]) {
        scope[item.scopeId].push(item);
      } else {
        scope[item.scopeId] = [];
        scope[item.scopeId].push(item);
      }
    } else if (item.subScopeId) {
      if (subScope[item.subScopeId]) {
        subScope[item.subScopeId].push(item);
      } else {
        subScope[item.subScopeId] = [];
        subScope[item.subScopeId].push(item);
      }
    }
  });

  return {
    scope,
    subScope,
  };
};

let HandleGroupingQuestions = (data, isSub) => {
  let orderedScopes = [];
  for (const key of Object.keys(data)) {
    let sortedScope = {
      [isSub ? "subScopeId" : "scopeId"]: key,
      likertQuestions: [],
      polaredQuestions: [],
      checkboxQuestions: [],
      testItemQuestions: [],
    };
    data[key].forEach((question) => {
      if (question.likertId) {
        sortedScope.likertQuestions.push({
          answer: question.answer,
          questionId: question.questionId,
          likertId: question.likertId,
        });
      } else if (question.testItemId) {
        sortedScope.testItemQuestions.push({
          answer: question.answer,
          questionId: question.questionId,
          testItemId: question.testItemId,
        });
      } else if (question.polaredId) {
        sortedScope.polaredQuestions.push({
          answer: question.answer,
          questionId: question.questionId,
          polaredId: question.polaredId,
        });
      } else if (question.cboxId) {
        sortedScope.checkboxQuestions.push({
          isChecked: question.checked,
          questionId: question.questionId,
          cboxId: question.id,
        });
      }
    });
    orderedScopes.push(sortedScope);
  }
  return orderedScopes;
};
