import React from "react";
import ConsistencyAnalysis from "./ConsistencyAnalysis";
import TendencyNumbers from "./TendencyNumbers";

const AnsweringTendencyDesign_1 = ({ item }) => {
  return (
    <div
      className="mb-5 mt-5"
      style={{
        padding: "50px",
        background: "white",
        borderRadius: "20px",
      }}
    >
      <ConsistencyAnalysis score={item?.consistencyScore} />
      <TendencyNumbers numbers={item?.inventoryLeanNumbers} />
    </div>
  );
};
export default AnsweringTendencyDesign_1;
