import React from "react";
import { Col, ProgressBar, Row } from "react-bootstrap";

export default function Summary_LoadingBar({ item, isSub }) {
  let elementScore = item.normScore ? Number(item.normScore) : item.score;

  return (
    <Row className="mb-3 mx-0">
      <Col xs={3} className={"align-content-center"}>
        <Row>
          <Col className={"align-content-center " + (isSub ? "sub-scope-name-wrap-up" : "main-scope-name-wrap-up")}>
            {item.name}
          </Col>
          <Col style={{ display: "inline-block", float: "right" }}>
            <div className={!isSub ? "main-scope-score-wrap-up" : "sub-scope-score-wrap-up"}>{elementScore}</div>
          </Col>
        </Row>
      </Col>
      <Col xs={9} className={"align-content-center"}>
        <ProgressBar
          variant={isSub ? "sub-scope" : "main-scope"}
          now={elementScore}
          label={`${elementScore}%`}
          style={{ height: "20px" }}
        />
      </Col>
    </Row>
  );
}
