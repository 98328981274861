import { createSlice } from "@reduxjs/toolkit";
import { createCompany, deleteCompany, getAllCompanies, updateCompany } from "./Thunk";

//TODO: getCompanyById needs to be implemented here

export const company = createSlice({
  name: "company",
  initialState: {
    id: "",
    name: "",
    companyList: [],
    isLoading: false,
    isUpdateOperation: false,
  },
  reducers: {
    UpdateName(state, action) {
      state.name = action.payload;
    },
    UpdateCompanyState(state, action) {
      state.isUpdateOperation = action.payload.isUpdateOperation;
      state.name = action.payload.name;
      state.id = action.payload.id;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createCompany.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createCompany.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deleteCompany.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteCompany.fulfilled, (state, action) => {
      console.log(action.payload);

      state.isLoading = false;
    });

    builder.addCase(updateCompany.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateCompany.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getAllCompanies.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getAllCompanies.fulfilled, (state, action) => {
      state.companyList = action.payload;
      state.isLoading = false;
    });
  },
});

export const { UpdateName, UpdateCompanyState } = company.actions;

export default company.reducer;
