import React from "react";
import PdfConsistencyAnalysis from "./PdfConsistencyAnalysis";
import PdfTendencyNumbers from "./PdfTendencyNumbers";
import { Row } from "react-bootstrap";

const AnsweringTendencyDesign_1 = ({ item }) => {
  return (
    <Row
      className="mb-5 mt-5"
      style={{
        height: Number(item.height),
        padding: "20px 0px",
        background: "rgb(247, 247, 244)",
        borderRadius: "20px",
      }}
    >
      <PdfConsistencyAnalysis score={item?.consistencyScore} />
      <PdfTendencyNumbers numbers={item?.inventoryLeanNumbers} />
    </Row>
  );
};
export default AnsweringTendencyDesign_1;
