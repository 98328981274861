import React, { useEffect, useState, useRef } from "react";
import Webcam from "react-webcam";
import "animate.css";
import Overlay from "../../../../../SharedComponents/Overlay";

export function Counter(props) {
  const [startDate] = useState(new Date());
  const [intervalId, setIntervalId] = useState(null);
  const [remainingSecond, setRemainingSecond] = useState(props.duration * 60);
  const [counterCssClass, setCounterCssClass] = useState("counter-container");
  const [showPermissionOverlay, setShowPermissionOverlay] = useState(true);

  const CheckPermission = async () => {
    if (!props.isB2b) return;
    return navigator.permissions.query({ name: "camera" }).then((permissionObj) => {
      if (permissionObj.state === "denied" || permissionObj.state === "prompt") {
        setShowPermissionOverlay(true);
        return false;
      } else {
        setShowPermissionOverlay(false);
        return true;
      }
    });
  };

  useEffect(() => {
    if (!intervalId) setIntervalId(setInterval(HandleRemainingTime, 1000));
  }, []);

  useEffect(() => {
    if (props.stopCounter) clearInterval(intervalId);
    if (remainingSecond - 1 <= 0) {
      props.Finish();
      clearInterval(intervalId);
    }
  });

  const videoConstraints = {
    width: 640,
    height: 480,
    facingMode: "user",
  };

  const webcamRef = useRef(null);

  let HandleMinute = (seconds) => {
    return Math.floor(seconds / 60);
  };
  let HandleSecond = (seconds) => {
    return Math.floor(seconds % 60);
  };

  let HandleRemainingTime = async () => {
    if (props.stopCounter) return clearInterval(intervalId);
    if (props.isB2b && props.openCam) {
      const permission = await CheckPermission();
      if (!permission) return;
    }

    let now = new Date();
    let secondsPassed = Math.floor((now - startDate) / 1000);
    let remaining = props.duration * 60 - secondsPassed;

    if (props.isB2b && props.openCam && remaining % 15 === 0) {
      if (remaining % 15 === 0) {
        props.update(remaining, getFileFromBase64(webcamRef.current.getScreenshot()));
      } else props.update(remaining);
    }
    if (remaining === 10) {
      setCounterCssClass("counter-container-danger animate__animated  animate__flash animate__infinite	infinite");
    }
    if (remaining <= 0) {
    } else {
      setRemainingSecond(remaining);
    }
  };

  function getFileFromBase64(string64) {
    const fileName = new Date().getTime() + ".jpeg";
    const trimmedString = string64.replace("data:image/jpeg;base64,", "");
    const imageContent = atob(trimmedString);
    const buffer = new ArrayBuffer(imageContent.length);
    const view = new Uint8Array(buffer);

    for (let n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }
    const type = "image/jpeg";
    const blob = new Blob([buffer], { type });
    return new File([blob], fileName, {
      lastModified: new Date().getTime(),
      type,
    });
  }

  let remainingMinute = HandleMinute(remainingSecond);
  let remainingSecond_ = HandleSecond(remainingSecond);
  let stop = () => {
    clearInterval(intervalId);
  };
  return (
    <>
      {props.openCam && props.isB2b && showPermissionOverlay && (
        <Overlay magnify={true} text="Devam edebilmek için kamera erişim izni verin lütfen" />
      )}
      <div className={counterCssClass}>
        {props.isB2b && props.openCam && (
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            minScreenshotWidth={180}
            minScreenshotHeight={180}
            style={{
              width: "90px",
              height: "90px",
              position: "absolute",
              left: "-100px",
              top: "-10px",
            }}
          />
        )}
        <span className="red-dot"></span>
        <h1 className="timer-second-minute-container" onClick={stop}>
          {remainingMinute < 10 && "0"}
          {remainingMinute}
        </h1>
        <h1> :</h1>
        <h1 className="timer-second-minute-container">
          {remainingSecond_ < 10 && "0"}
          {remainingSecond_}
        </h1>
      </div>
    </>
  );
}
