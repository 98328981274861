import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import AnsweringPageFooter from "../SharedComponents/AnswerIngPageFooter";
import TextSection from "../SharedComponents/TextSection";
import "animate.css";

export function Info({ Finish, info, clickCounter }) {
  var user = localStorage.getItem("cpdata");
  if (JSON.parse(user)?.userRole[0] == "SuperAdmin") {
    Finish();
  }
  useEffect(() => window.scrollTo({ top: 0, behavior: "smooth" }));

  return (
    <>
      <TextSection text={info?.infoTitle} />
      <Container
        className="animate__animated  animate__fadeInDown animate__faster"
        style={{ paddingTop: "200px", maxWidth: "864px" }}
      >
        <Card
          style={{
            background: "#FFF",
            color: "white",
            textAlign: "center",
            border: "0px",
            fontFamily: "Plus Jakarta Sans",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "28px" /* 140% */,
            letterSpacing: "-0.2px",
            margin: "0px 15px",
          }}
        >
          <Card.Body
            style={{
              background: "white",
              color: "#747474",
              borderRadius: "25px",
            }}
          >
            <Container>
              <div
                style={{
                  padding: "20",
                  fontSize: window.innerWidth <= 770 ? "14px" : "20px",
                }}
                dangerouslySetInnerHTML={{
                  __html: info?.infoHtml,
                }}
              ></div>
            </Container>
          </Card.Body>
        </Card>
        <Container style={{ paddingTop: "32px", marginBottom: "100px" }}>
          <p
            style={{
              fontSize: window.innerWidth <= 770 ? "12px" : "15px",
              color: "#747474",
              fontFamily: "Plus Jakarta Sans",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "15.6px",
              padding: "0px 15px",
            }}
          >
            <span style={{ fontWeight: "700" }}> Telif Hakları: </span> Bu envanterin tüm hakları saklıdır. Hiçbir
            nedenle, envanterin bir kısmının ya da tamamının kopyalanması, ekran görüntüsünün
            alınması,fotoğrafının/videosunun çekilmesi, envanter içeriğinin kullanılması ya da paylaşılması yasaktır. Bu
            kurallara uyulmaması nedeniyle ortaya çıkacak her türlü yasal ceza ve yükümlülüğü, ilgili eylemi
            gerçekleştiren kişi peşinen kabul etmiş sayılır.
          </p>
        </Container>
      </Container>
      <AnsweringPageFooter
        data={null}
        onClickFunction={Finish}
        onMouseDownFunction={null}
        buttonText={clickCounter === 0 ? "Uygulamaya Başla" : "Devam Et"}
        buttonClassName={" animate__animated   animate__flash animate__faster"}
        isDisabled={false}
      />
    </>
  );
}
