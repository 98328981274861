import React from "react";
import ReportItemEntityListRow from "./ReportItemEntityListRow";

export default function BasicGraphSummaryEntity({ entity, children }) {
  return (
    <div className="report-scheme-report-item-row">
      <ReportItemEntityListRow entity={entity} itemName="Özet" />
      {children && children}
    </div>
  );
}
