import React from "react";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import { useDispatch } from "react-redux";

import { DeleteInventory } from "../../../Store/Thunk";

const MySwal = withReactContent(Swal);

export default function DeleteButton({ inventory }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const Delete = () => {
    MySwal.fire({
      title: <strong>{t("areYouSure")}</strong>,
      html: <i>{t("successAlertHeading")}</i>,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("yes"),
      cancelButtonText: t("giveUp"),
      confirmButtonColor: "#0d6efd",
      cancelButtonColor: "red",
    }).then(function (res) {
      if (res.isConfirmed) {
        dispatch(
          DeleteInventory({
            id: inventory.id,
            schemeImagesJson: GetSchemeImageNames(),
          })
        );
      }
    });
  };

  const GetSchemeImageNames = () => {
    let imageNames = [];
    if (inventory.reportScheme?.adminWrapUpJson) {
      var scheme = JSON.parse(inventory.reportScheme?.adminWrapUpJson);
      scheme.forEach((item) => {
        if (item.componentName === "ImageSpace") return imageNames.push(item.imageName);
      });
    }

    return JSON.stringify(imageNames);
  };

  return (
    <Button size="sm" variant="danger" onClick={Delete}>
      {t("delete")}
    </Button>
  );
}
