import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ApiHelper from "../../../../Helpers/ApiHelper";

import { ChecboxesImage } from "./Questions/ChecboxesImage";
import { PolarLikertSlider } from "./Questions/PolarLikertSlider";
import { Info } from "../SharedInfoPages/Info";
import { CandidateInfo } from "../SharedInfoPages/CandidateInfo";
import Overlay from "../../../../SharedComponents/Overlay";

import UseAuthHelper from "../../../../Helpers/Auth/UseAuthHelper";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export function GoToInventory(props) {
  const { ticketId } = useParams();
  const { invId } = useParams();
  const { auth } = UseAuthHelper();
  const user = auth?.data;
  const navigate = useNavigate();
  const [ticket, setTicket] = useState(null);

  const [isOverlayVisible, setIsOverlayVisible] = useState(true);
  const [applicationData, setApplicationData] = useState({
    questions: null,
    checkboxes: null,
    invId: 0,
    answers: null,
    info: null,
  });
  const [isInfoGot, setIsInfoGot] = useState(ticketId ? true : false);
  const [isInfoShowed, setIsInfoShowed] = useState();
  const [isCheckboxesFinished, setIsCheckboxesFinished] = useState(false);

  const [postData, setPostData] = useState({
    demoResultId: null,
    info: {},
    checkboxAnswersJson: [],
    questionAnswersJson: [],
  });

  const [finishedTestData, setFinishedTestData] = useState(false);

  useEffect(() => {
    document.getElementById("login")?.remove();
    if (invId || ticketId) {
      if (props.b2c) GetInventoryImageB2c();
      else if (props.b2b) GetTestImageByTicketIdAndHandleClickCounter();
    } else {
      setIsOverlayVisible(false);
      MySwal.fire({
        title: <strong>Hata</strong>,
        html: <i>Bozuk Link</i>,
        icon: "error",
      });
      return;
    }
  }, []);

  let GetTestImageByTicketIdAndHandleClickCounter = () => {
    ApiHelper.getAsync(
      "ticket/GetTicketTestImage",
      { ticketId: ticketId },
      function (result) {
        setIsOverlayVisible(false);
        if (result.data.statusCode === 1) {
          HandleTicketStateAndOpenApplicationForB2b(result.data.data);
        } else {
          MySwal.fire({
            title: <strong>Hata</strong>,
            html: <i>{result.data.data}</i>,
            icon: "error",
          });
        }
      },
      user.token
    );
  };

  let HandleTicketStateAndOpenApplicationForB2b = (data) => {
    setTicket(data);
    if (data.ticketResult) {
      data.test.duration = data.ticketResult.remainingDuration && data.ticketResult.remainingDuration / 60;
      data.test.testImage.questions = data.ticketResult.questionAnswersJson && data.ticketResult.questionAnswersJson;

      data.test.testImage.adjQuestions = data.ticketResult.checkboxAnswersJson && data.ticketResult.checkboxAnswersJson; // uygulama baslamis ilerlemis ancak yeniden giriyor eski cevap anahtarini bos cevap anahtari ile degistiriyorum
    }
    HandlequestionStatesAndOpenApplication(data.test);
  };

  let GetInventoryImageB2c = () => {
    ApiHelper.getAsync(
      "user/GetInventoryImage",
      { invId },
      function (result) {
        setIsOverlayVisible(false);
        if (result.data.statusCode === 1) {
          HandlequestionStatesAndOpenApplication(result.data.data);
        } else {
          MySwal.fire({
            title: <strong>Hata</strong>,
            html: <i>Bozuk Link</i>,
            icon: "error",
          });
        }
      },
      null
    );
  };

  let HandlequestionStatesAndOpenApplication = (inv) => {
    const questions = JSON.parse(inv.questionsImage.questionsJson);
    const checkboxes = JSON.parse(inv.questionsImage.checkboxQuestionsJson);
    const answers = JSON.parse(inv.questionsImage.answersJson);
    const info = inv.info;

    setApplicationData({
      questions,
      checkboxes,
      invId: inv.id,
      answers,
      info,
    });

    if (checkboxes === null) setIsCheckboxesFinished(true);
  };

  let GoBackToAdjQuestions = () => {
    setIsCheckboxesFinished(false);
  };

  let FinishCheckboxes = (checkboxAnswersJson) => {
    setIsCheckboxesFinished(true);
    if (!applicationData?.questions?.length) {
      PostFinishedInventory(null, checkboxAnswersJson);
    } else {
      setPostData((pd) => ({ ...pd, checkboxAnswersJson }));
    }
  };

  let FinishCandidateInfo = (info) => {
    setIsOverlayVisible(true);
    info.inventoryId = applicationData.invId;
    ApiHelper.postAsync(
      "user/CreateDemoResult",
      info,
      function (result) {
        setIsOverlayVisible(false);
        if (result.data.statusCode === 1) {
          setIsInfoGot(true);
          setPostData((pd) => ({
            ...pd,
            info,
            demoResultId: result.data.data.id,
          }));
        } else {
          MySwal.fire({
            title: <strong>Hata</strong>,
            html: <i>Bir hata oluştu</i>,
            icon: "error",
          });
        }
      },
      null
    );
  };

  let CreateTicketResut = () => {
    setIsOverlayVisible(true);
    ApiHelper.postAsync(
      "ticket/CreateTicketResult",
      {
        ticketId: ticketId,
        checkboxAnswersJson: JSON.stringify(applicationData.adjQuestions),
        questionAnswersJson: JSON.stringify(applicationData.questions),
        remainingDuration: applicationData.duration * 60,
      },
      function (result) {
        setIsOverlayVisible(false);
        if (result.data.statusCode === 1) {
          setTicket({ ...ticket, ticketResult: result.data.data });
          setIsInfoShowed(true);
        } else {
          //todo kayi alinmazsa modal goster
        }
      },
      user.token
    );
  };

  let FinishInfo = () => {
    if (props.b2b && !ticket?.ticketResult && ticket?.clickCounter <= 1) CreateTicketResut();
    else setIsInfoShowed(true);
  };

  let DismissFinish = (questionAnswersJson) => {
    setPostData((pd) => ({ ...pd, questionAnswersJson }));
  };

  let FinishAnsweringInventory = (questionAnswersJson) => {
    PostFinishedInventory(questionAnswersJson);
  };

  let UpdateQuestionAnswersJsonB2b = async (questionAnswersJson) => {
    ApiHelper.postAsync(
      "ticket/UpdateTicketResultQuestionAnswersJson",
      {
        id: ticket?.ticketResult.id,
        questionAnswersJson: JSON.stringify(questionAnswersJson),
      },
      function (result) {},
      user.token
    );
  };

  let PostFinishedInventory = async (questionAnswersJson, checkboxAnswersJson) => {
    setIsOverlayVisible(true);

    let finishingInventoryPostObj = {
      id: props.b2c ? postData.demoResultId : ticket?.ticketResult.id,
      inventoryId: applicationData.invId,
      checkboxAnswersJson: JSON.stringify(checkboxAnswersJson ? checkboxAnswersJson : postData.checkboxAnswersJson),
      questionAnswersJson: JSON.stringify(questionAnswersJson),
      isFinished: true,
      candidateEmail: user?.userName,
      candidateName: user?.demography?.name,
    };

    ApiHelper.postAsync(
      props.b2c ? "user/UpdateDemoResult" : "ticket/updateTicketResult",
      finishingInventoryPostObj,
      function (result) {
        setIsOverlayVisible(false);
        if (result?.data?.statusCode === 1) {
          setFinishedTestData(result.data.data);
          result.data.data.invId = applicationData.invId;
          if (props.b2c) navigate("/idemoResult/" + finishingInventoryPostObj.id, { state: result.data.data });
          else if (ticket?.allowPdf) navigate("/iwrapup/" + finishingInventoryPostObj.id);
        } else {
          MySwal.fire({
            title: <strong>Hata</strong>,
            html: <i>Bir problem olustu: {result.data.data} </i>,
            icon: "error",
          });
        }
      },
      props.b2c ? null : user.token
    );
  };

  let HandleFinishButton = () => {
    PostFinishedInventory(postData.questionAnswersJson);
  };

  let HandleShowThanks = () => {
    if (finishedTestData && (!props.b2c || !ticket?.allowPdf)) return true;
    else return false;
  };

  return (
    <>
      {isOverlayVisible && <Overlay />}
      {applicationData && applicationData.invId !== null && applicationData.invId !== "" && !isInfoGot && (
        <CandidateInfo Finish={FinishCandidateInfo} />
      )}
      {applicationData &&
        applicationData.invId !== null &&
        applicationData.invId !== "" &&
        isInfoGot &&
        !isInfoShowed && <Info Finish={FinishInfo} info={applicationData.info} clickCounter={ticket?.clickCounter} />}

      {isInfoGot && isInfoShowed && !isCheckboxesFinished && applicationData && applicationData.checkboxes && (
        <ChecboxesImage
          q={postData.checkboxAnswersJson?.length > 0 ? postData.checkboxAnswersJson : applicationData.checkboxes}
          Finish={FinishCheckboxes}
          isOnlyChecklist={!applicationData?.questions?.length}
        />
      )}
      {!finishedTestData &&
        isInfoGot &&
        isInfoShowed &&
        isCheckboxesFinished &&
        applicationData &&
        applicationData.questions?.length && (
          <PolarLikertSlider
            isB2b={props.b2b}
            questions={applicationData.questions}
            answers={applicationData.answers}
            duration={applicationData.duration}
            openCam={ticket?.openCam}
            finish={FinishAnsweringInventory}
            dismissFinish={DismissFinish}
            update={UpdateQuestionAnswersJsonB2b}
            goBack={GoBackToAdjQuestions}
            isFinished={!finishedTestData && postData.questionAnswersJson}
            HandleFinishButton={HandleFinishButton}
          />
        )}

      {HandleShowThanks() && <h1 className="thank-message"> Katılımınız için teşekkürler </h1>}
      {window.innerWidth <= 500 && <div style={{ height: "200px", width: "100%" }}></div>}
    </>
  );
}
