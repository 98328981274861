import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Container } from "react-bootstrap";
import AnsweringPageFooter from "../../SharedComponents/AnswerIngPageFooter";

import FinishingModal from "../../SharedComponents/FinishingModal";
import TextSection from "../../SharedComponents/TextSection";
import ScrollableCard from "../../../../../SharedComponents/ScrollableCard";

export function ChecboxesImage({ q, Finish, isOnlyChecklist }) {
  const [checkboxAnswersJson, setCheckboxAnswersJson] = useState([]);

  const [finishModalShow, setFinishModalShow] = useState(false);

  useEffect(() => {
    if (checkboxAnswersJson.length == 0) setCheckboxAnswersJson(q);
  }, []);

  let HandleOnChange = (id) => {
    checkboxAnswersJson.forEach((x) => {
      if (x.questionId === id) {
        x.checked = !x.checked;
      }
    });
    setCheckboxAnswersJson(checkboxAnswersJson);
  };

  let Finish_ = () => {
    Finish(checkboxAnswersJson);
    setFinishModalShow(false);
  };

  let DismisFinish = () => {
    setFinishModalShow(false);
  };

  let FinishCheckList = () => {
    if (isOnlyChecklist) setFinishModalShow(true);
    else Finish(checkboxAnswersJson);
  };

  let HandleAdjQuestions = () => {
    return q.map((q, i) => {
      return (
        <Row key={i} className="justify-content-center show-cursor">
          <Col xs="8" md="5" style={{ margin: "10px" }}>
            <Form.Check
              style={{
                borderRadius: "16px",
                marginTop: "-10px",
                background: "#FFF",
                padding: "17px 10px 13px 43px",
                display: "inline-flex",
                width: "100%",
                height: "100%",
              }}
              label={
                <h5
                  className="show-cursor"
                  style={{
                    fontSize: window.innerWidth <= 500 ? "15px" : "18px",
                    paddingLeft: "10px",
                    color: "#000",
                    fontFamily: "Plus Jakarta Sans",
                    fontWeight: "600",
                    lineHeight: "normal",
                  }}
                >
                  {q.text}
                </h5>
              }
              onClick={() => HandleOnChange(q.questionId)}
              type="checkbox"
              id={q.text}
              checked={q?.checked}
            />
          </Col>
        </Row>
      );
    });
  };

  return (
    <>
      <TextSection
        text="Aşağıdaki ifadelerden sizi tanımladığını düşündüklerinizi
          işaretleyiniz"
        class="checkbox-image-info-text"
      />

      <ScrollableCard>
        <Container
          style={{ marginTop: "220px", marginBottom: 150 }}
          className="animate__animated  animate__fadeInDown "
        >
          {HandleAdjQuestions()}
        </Container>
      </ScrollableCard>
      <AnsweringPageFooter
        onClickFunction={FinishCheckList}
        isDisabled={false}
        buttonText={isOnlyChecklist ? "Tamamla" : "Devam"}
      />

      <FinishingModal show={finishModalShow} Finish={Finish_} DismisFinish={DismisFinish} />
    </>
  );
}
