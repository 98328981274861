import React, { useEffect, useState } from "react";

import TopNavigation from "./TopNavigation";
import MenuButton from "./MenuButton";
import UseAuthHelper from "../../Helpers/Auth/UseAuthHelper";
// TODO: translate these to english
export const PAGES = {
  0: ["Dashboard", "Admin", "/dash"],
  1: ["Projects", "Admin", "/projects"],
  2: ["Results", "Admin", "/results"],
  3: ["Settings", "Admin", "/settings"],
  4: ["Uygulamalar", "User", "/userdash"],
  5: ["Results", "User", "/myresults"],
  6: ["Dashboard", "SuperAdmin", "/sdash"],
  7: ["Assessment", "SuperAdmin", "/assessment/"],
  8: ["Inventory", "SuperAdmin", "/inventory"],
  9: ["Test", "SuperAdmin", "/test"],
  10: ["Survey", "SuperAdmin", "/Survey/"],
  11: ["Kredi", "SuperAdmin", "/load"],
  12: ["Results", "SuperAdmin", "/ApplicationResults/"],
  13: ["Company", "SuperAdmin", "/Company/"],
};
export default function AppMenu(props) {
  const { auth } = UseAuthHelper();
  const user = auth?.data;

  let landingPage = "";

  if (user.userRole[0] === "User") landingPage = "Uygulamalar";
  if (user.userRole[0] === "Admin") landingPage = "Anasayfa";
  if (user.userRole[0] === "SuperAdmin") landingPage = "Anasayfa";
  const [selected, setSelected] = useState(landingPage);

  const pages = Object.values(PAGES);

  useEffect(() => {
    pages.forEach((page) => {
      if (window.location.hash.substring(1) === page[2]) setSelected(page[0]);
    });
  }, []);

  const HandleSideMenuButtonClick = (page) => {
    setSelected(page[0]);
    window.location.href = window.location.origin + "/#" + page[2];
  };

  return (
    <div className="side-menu-container">
      <TopNavigation pageName={selected} userName={props.userName} />
      <div className="sidemenu-button-container">
        {pages.map((page, i) => {
          if (page[1] === props.role)
            return (
              <MenuButton
                key={i + "page"}
                page={page[0]}
                pageObj={page}
                selected={selected}
                HandleSideMenuButtonClick={HandleSideMenuButtonClick}
              />
            );
        })}
      </div>
    </div>
  );
}
