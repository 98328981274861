import React from "react";

import Summary_1 from "./Designs/Summaries/Summary_1";
import Summary_2 from "./Designs/Summaries/Summary_2";
import Summary_LoadingBar from "./Designs/Summaries/Summary_LoadingBar";
import Detail_1 from "./Designs/Details/Detail_1";
import Detail_2 from "./Designs/Details/Detail_2";
import Detail_3 from "./Designs/Details/Detail_3";
import Detail_4 from "./Designs/Details/Detail_4";
import Detail_5 from "./Designs/Details/Detail_5";
import Detail_LoadingBar from "./Designs/Details/Detail_LoadingBar";
import MonoText from "./Designs/Monos/MonoText";
import DualText from "./Designs/Duals/DualText";
import MonoText_2 from "./Designs/Monos/MonoText_2";
import TestScaleDesign_1 from "./Designs/TestScaleDesign_1";
import AnsweringTendencyDesign_1 from "./Designs/CalculatedTendencies/AnsweringTendencyDesign_1";
import { designTypes } from "../../../../CreateApplication/ReportItems/ReportItemBase/Components/DesignTypeDropdown";

import EmptySpace from "./HtmlElements/EmptySpace";
import MonoSpace from "./HtmlElements/MonoSpace";
import DualSpace from "./HtmlElements/DualSpace";
import ImageSpace from "./HtmlElements/ImageSpace";
import { HandleTestScore, NormalizeScore } from "../../../../../Helpers/ScoreNormalizationHelper";

export const SelectPdfReportItemDesign = (item, type) => {
  const designs = {
    Summary_1,
    Summary_2,
    Summary_LoadingBar,
    Detail_1,
    Detail_2,
    Detail_3,
    Detail_4,
    Detail_5,
    Detail_LoadingBar,
    MonoText,
    DualText,
    MonoText_2,
    AnsweringTendencyDesign_1,
    TestScaleDesign_1,
  };

  const isSub = item.subScopeId ? true : false;

  const GetNameProperty = (item) => {
    for (var name in designTypes) {
      var value = designTypes[name];
      if (value === item.designType) return name;
    }
  };

  const renderSwitch = (item) => {
    switch (item.componentName) {
      case "EmptySpace":
        return <EmptySpace key={Math.random()} item={item} />;
      case "MonoSpace":
        return <MonoSpace key={Math.random()} item={item} />;
      case "DualSpace":
        return <DualSpace key={Math.random()} item={item} />;
      case "ImageSpace":
        return <ImageSpace key={Math.random()} item={item} />;
      default:
        return CreateDynamicComponent(item, Math.random());
    }
  };

  const CreateDynamicComponent = (item) => {
    if (type === "test" && item.scales) HandleTestScore(item.scales, item.score);
    else if (type === "test" && !item.scales) HandleTestScore(item.scale?.ranges, item.score);
    else if (type === "inventory" && item.componentName !== "AnsweringTendencyEntity") NormalizeScore(item);

    const SpecificComponent = designs[GetNameProperty(item)];
    return <SpecificComponent item={item} isSub={isSub} />;
  };

  return renderSwitch(item);
};
