import React from "react";
import { Col, ProgressBar, Row } from "react-bootstrap";

export default function PdfParameterProgressBar({ name, score }) {
  if (score <= 0) score = 0;
  else score = Number(score).toFixed(0);
  return (
    <Row className="mb-3">
      <Col xs="3">
        <Row>
          <Col className="test-scale-name-pdf align-content-center">{name}</Col>
          <Col xs={"auto"} style={{ display: "inline-block", float: "right" }} className={"align-content-center"}>
            <div className={"_main-scope-score-pdf text-white "}>{score}</div>
          </Col>
        </Row>
      </Col>
      <Col xs="9" className={"align-content-center"}>
        <ProgressBar style={{ height: "25px" }} striped variant="warning" now={score} label={score} />
      </Col>
    </Row>
  );
}
