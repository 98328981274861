import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Col, Row } from "react-bootstrap";
import IconSvg from "./AppMenu/IconSvg";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

export default function AppHeader() {
  const { t } = useTranslation();
  const isTurkishSelected = i18next.language === "tr";

  const SelectLanguage = (lang) => {
    i18next.changeLanguage(lang).catch((err) => {
      console.log(err);
    });
  };
  const IsVisibleLoginAndLangButtons = () => {
    let href = window.location.href;
    if (href.includes("GoToApplication") || href.includes("app") || href.includes("demoResult")) return false;
    return true;
  };

  return (
    <Navbar fixed="top" style={{ background: "#2855AC" }}>
      <Container>
        <Navbar.Brand href="/">
          <h2 style={{ color: "#fFF", height: "100%" }}>
            <IconSvg icon={"cp-logo"} height={40} width={40}></IconSvg>
            <span> Cus</span>-<span>Pro</span>
            <span style={{ fontSize: "16px", color: "#F6C344" }}>beta</span>
          </h2>
        </Navbar.Brand>
        {IsVisibleLoginAndLangButtons() && (
          <Container>
            <Row xs={"auto"} className="justify-content-end ">
              <Col className={"px-4 pt-2"}>
                <Row>
                  <Col
                    className={(isTurkishSelected && "language-button d-none d-sm-block") + " px-1 py-0 btn border-0"}
                    onClick={() => {
                      SelectLanguage("tr");
                    }}
                  >
                    <IconSvg icon="flag-tr" height={24} width={24} />
                  </Col>
                  <Col className={"d-none d-sm-block px-0"}>
                    <IconSvg icon="dot" height={20} width={20} />
                  </Col>
                  <Col
                    className={(!isTurkishSelected && "language-button d-none d-sm-block") + " px-1 py-0 btn border-0"}
                    onClick={() => {
                      SelectLanguage("en");
                    }}
                  >
                    <IconSvg icon="flag-uk" height={24} width={24} />
                  </Col>
                </Row>
              </Col>

              <Col xs={2} className={"justify-content-end"}>
                <Row xs={"auto"} className={"justify-content-md-end "}>
                  <Nav.Item>
                    <Nav.Link id="login" href="/#/login" style={{ height: 24 }}>
                      <Row className={"px-4 py-2 rounded-1 align-content-center"} style={{ color: "#F6C344" }}>
                        <Col style={{ paddingLeft: 0 }}>
                          <IconSvg icon={"login"} width={24} height={24} />
                        </Col>
                        <Col className={"d-none  d-sm-block fw-semibold px-0"}>{t("login")}</Col>
                      </Row>
                    </Nav.Link>
                  </Nav.Item>
                </Row>
              </Col>
            </Row>
          </Container>
        )}
      </Container>
    </Navbar>
  );
}
