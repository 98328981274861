import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { switchVisibilityByGroup } from "../Store";
import { useDispatch } from "react-redux";

const MassFilterButtonGroup = (props) => {
  const dispatch = useDispatch();

  return (
    <Row className={"justify-content-between mt-1"}>
      <Col xs={"auto"}>
        <Button
          size={"sm"}
          onClick={() => {
            dispatch(switchVisibilityByGroup({ componentNameOfGroupToBeShown: "All" }));
          }}
        >
          Hepsini Seç
        </Button>
      </Col>
      <Col xs={"auto"}>
        <Button
          size={"sm"}
          onClick={() => {
            dispatch(switchVisibilityByGroup({ componentNameOfGroupToBeShown: "None" }));
          }}
        >
          Hepsini Kaldır
        </Button>
      </Col>
      <Col xs={"auto"}>
        <Button
          size={"sm"}
          onClick={() => {
            dispatch(switchVisibilityByGroup({ componentNameOfGroupToBeShown: "BasicGraphSummaryEntity" }));
          }}
        >
          Sadece Özet Seç
        </Button>
      </Col>
      <Col xs={"auto"}>
        <Button
          size={"sm"}
          onClick={() => {
            dispatch(switchVisibilityByGroup({ componentNameOfGroupToBeShown: "BasicGraphDetailEntity" }));
          }}
        >
          Sadece Detay Seç
        </Button>
      </Col>
      <Col xs={"auto"}>
        <Button
          size={"sm"}
          onClick={() => {
            dispatch(switchVisibilityByGroup({ componentNameOfGroupToBeShown: "MonoTextuaryEntity" }));
          }}
        >
          Sadece Metin Seç
        </Button>
      </Col>
      <Col xs={"auto"}>
        <Button
          size={"sm"}
          onClick={() => {
            dispatch(switchVisibilityByGroup({ componentNameOfGroupToBeShown: "DualTextuaryEntity" }));
          }}
        >
          Sadece Dual Seç
        </Button>
      </Col>
    </Row>
  );
};
export default MassFilterButtonGroup;
