export const api = {
  createCompany: "company/CreateCompany",
  updateCompany: "company/UpdateCompany",
  deleteCompany: "company/DeleteCompany",
  getCompanyById: "company/GetCompanyById",
  getAllCompanies: "company/GetAllCompanies",
};

export default class CompaniesAPI {
  constructor(httpsClient, cache) {
    this.httpsClient = httpsClient;
    this.cache = cache;
  }

  async createCompany(data, token) {
    return this.post(api.createCompany, data, token, true, true);
  }

  async deleteCompany(data, token) {
    return this.get(api.deleteCompany, data, token, true, true);
  }

  async updateCompany(data, token) {
    return this.post(api.updateCompany, data, token, true, true);
  }

  async getAllCompanies(data, token) {
    return this.get(api.getAllCompanies, data, token);
  }

  async getCompanyById(data, token) {
    return this.get(api.getCompanyById, data, token);
  }

  async get(url, data, token, eAlert, sAlert) {
    const response = await this.httpsClient.get(url, data, token, eAlert, sAlert);

    return response;
  }

  async post(url, data, token, eAlert, sAlert) {
    this.cache.remove("tests");

    return await this.httpsClient.post(url, data, token, eAlert, sAlert);
  }
}
