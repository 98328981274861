import React, { useEffect } from "react";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap/Modal";
import { Container } from "react-bootstrap";

import { updateTest } from "../../Store/Thunk";
import { updateEntityApplication, closeEntityModal } from "../../Store";

import { ScoringScale } from "../../../Test/Components/ScoringScale";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function UpdateTestModal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const entityData = useSelector((state) => state.testEntityList.entityModal.data);
  const isShow = useSelector((state) => state.testEntityList.entityModal.isShow);

  useEffect(() => {
    if (entityData.cleanScoringComp) {
      dispatch(
        updateEntityApplication({
          ...entityData,
          cleanScoringComp: false,
        })
      );
    }
  });

  const HandleAddScoreScale = (data) => {
    dispatch(
      updateEntityApplication({
        ...entityData,
        scales: data,
      })
    );
  };

  let UpdateTest = () => {
    dispatch(updateTest(entityData));
    dispatch(closeEntityModal());
  };

  const closeModal = () => {
    dispatch(closeEntityModal());
  };

  const updateData = (property, value) => {
    dispatch(
      updateEntityApplication({
        ...entityData,
        [property]: value,
      })
    );
  };

  return (
    <Modal show={isShow} fullscreen={true} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{t("update")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <h5>{t("firstName")}</h5>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder={t("enterTheName")}
              value={entityData?.name}
              onChange={(e) => updateData("name", e.target.value)}
            />
          </InputGroup>
          <h5>{t("explanation")}</h5>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder={t("enterDescription")}
              value={entityData?.description}
              onChange={(e) => updateData("description", e.target.value)}
            />
          </InputGroup>
          <h6>{t("duration")}</h6>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder={t("enterDuration")}
              type="number"
              value={entityData?.duration}
              onChange={(e) => updateData("duration", e.target.value)}
            />
          </InputGroup>
          <ScoringScale
            HandleAddScoreScale={HandleAddScoreScale}
            scale={entityData?.scales}
            data={entityData?.scales}
            clean={entityData?.cleanScoringComp}
          />
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={UpdateTest}>
          {t("update")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
