import React, { useLayoutEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { updateReportSchemeItemHeight } from "../Store";
import { Row } from "react-bootstrap";
import { SelectPdfReportItemDesign } from "../../../PdfDownloadButton/Components/PdfReportItems/ReportItems/SelectPdfReportItemDesign";

export default function HeightCalculator({ entity, type }) {
  const randomIntFromInterval = (min, max) => {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  };
  let entityWithRandomScore = { ...JSON.parse(JSON.stringify(entity)), score: randomIntFromInterval(1, 100) };

  const dispatch = useDispatch();
  const ref = useRef();
  useLayoutEffect(() => {
    if (entity.height === undefined) {
      const { height } = ref.current.getBoundingClientRect();
      if (entity.isVisible === true && entity.height === undefined) {
        dispatch(updateReportSchemeItemHeight({ height, order: entity.order }));
      }
    }
  }, []);

  return (
    <>
      {entity.height === undefined && (
        <div ref={ref} style={{ backgroundColor: "red" }}>
          <Row style={{ height: 0 }}></Row>
          <div style={{ width: "732px" }}>{SelectPdfReportItemDesign(entityWithRandomScore, type)}</div>
          <Row style={{ height: 0 }}></Row>
        </div>
      )}
    </>
  );
}
